import { useEffect, forwardRef, useImperativeHandle } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AppInput from "../../../../components/AppInput";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateHotelState } from "../../../../store/slices/hotel/hotelSlice";
import { RootState } from "../../../../store/store";
import AppPhoneInput from "../../../../components/AppPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";
import { emailRegex } from "../../../../utils/Regex";

interface FormTypes {
  primary_email: string;
  secondary_email: string;
  primary_phone: string;
  secondary_phone: string;
}

const ContactInfo = forwardRef(({}, ref) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { hotelId } = useParams();
  const hotelState = useSelector((state: RootState) => state?.hotel);

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  useImperativeHandle(ref, () => ({
    triggerHotelStep2FormValidation: async () => {
      if (hotelState?.is_step_2_complete) {
        const valid = await trigger();
        if (valid) {
          const updateHotelStateValue: any = {
            hotel_primary_email: getValues("primary_email"),
            hotel_secondary_email: getValues("secondary_email"),
            hotel_primary_phone_no: getValues("primary_phone"),
            hotel_secondary_phone_no: getValues("secondary_phone"),
          };
          dispatch(updateHotelState(updateHotelStateValue));
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  }));

  useEffect(() => {
    hotelState?.hotel_primary_email &&
      setValue("primary_email", hotelState?.hotel_primary_email);
    hotelState?.hotel_secondary_email &&
      setValue("secondary_email", hotelState?.hotel_secondary_email);
    hotelState?.hotel_primary_phone_no &&
      setValue("primary_phone", hotelState?.hotel_primary_phone_no);
    hotelState?.hotel_secondary_phone_no &&
      setValue("secondary_phone", hotelState?.hotel_secondary_phone_no);
  }, []);

  useEffect(() => {
    if (
      hotelState?.hotel_form_type === "add" &&
      hotelState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [hotelState?.hotel_form_type, hotelState?.is_all_state_reset]);

  const onSubmit: SubmitHandler<FormTypes> = (data) => {
    // console.log(data);
    const current_form_state = hotelState?.hotel_form_type;
    const updateHotelStateValue: any = {
      is_step_2_complete: true,
      hotel_primary_email: data?.primary_email,
      hotel_secondary_email: data?.secondary_email,
      hotel_primary_phone_no: data?.primary_phone,
      hotel_secondary_phone_no: data?.secondary_phone,
    };
    dispatch(updateHotelState(updateHotelStateValue));
    if (current_form_state === "add") {
      navigate("/hotel-management/add?step=3");
    } else {
      navigate(`/hotel-management/edit/${hotelId}?step=3`);
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">Please enter contact details</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="primary_email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: emailRegex,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Primary Email"
                isRequired
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.primary_email?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.primary_email?.type === "pattern" && (
            <span className="form-status error">Enter proper email</span>
          )}
        </div>
        <div className="mb-4">
          <Controller
            name="secondary_email"
            control={control}
            defaultValue=""
            rules={{
              required: false,
              pattern: emailRegex,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Secondary Email"
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.secondary_email?.type === "pattern" && (
            <span className="form-status error">Enter proper email</span>
          )}
        </div>
        {/* <div className="mb-4">
          <Controller
            name="primary_phone"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: /^[0-9]{1,15}$/,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Primary Phone No."
                isRequired
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.primary_phone?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.primary_phone?.type === "pattern" && (
            <span className="form-status error">Enter proper phone no</span>
          )}
        </div> */}
        <div className="mb-4">
          <Controller
            control={control}
            name="primary_phone"
            rules={{
              required: true,
              validate: (value: string) => {
                if (!!value) {
                  return isValidPhoneNumber(value);
                }
              },
            }}
            render={({ field: { onChange, value } }) => (
              <AppPhoneInput
                onChange={onChange}
                value={value}
                // defaultCountry={"IN"}
                label="Primary Phone No."
                isRequired
              />
            )}
          />
          {errors?.primary_phone?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.primary_phone?.type === "validate" && (
            <span className="form-status error">Enter a valid phone no.</span>
          )}
        </div>
        {/* <div className="mb-4">
          <Controller
            name="secondary_phone"
            control={control}
            defaultValue=""
            rules={{
              required: false,
              minLength: 8,
              maxLength: 10,
              pattern: /^[0-9]{1,15}$/,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Secondary Phone No."
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.secondary_phone?.type === "pattern" && (
            <span className="form-status error">Enter proper phone no</span>
          )}
        </div> */}
        <div className="m-0">
          <Controller
            control={control}
            name="secondary_phone"
            rules={{
              required: false,
              validate: (value: string) => {
                if (!!value) {
                  return isValidPhoneNumber(value);
                }
              },
            }}
            render={({ field: { onChange, value } }) => (
              <AppPhoneInput
                onChange={onChange}
                value={value}
                // defaultCountry={"IN"}
                label="Secondary Phone No."
              />
            )}
          />

          {errors?.secondary_phone?.type === "validate" && (
            <span className="form-status error">Enter a valid phone no.</span>
          )}
        </div>
        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Next
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
});

export default ContactInfo;
