import { Fragment, useEffect, forwardRef, useImperativeHandle } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import AppInput from "../../../../components/AppInput";
import AppTextarea from "../../../../components/AppTextarea";
import IconDelete from "@/images/icon__trash.svg";
import { Types } from "mongoose";
import { useSelector, useDispatch } from "react-redux";
import {
  updateHotelState,
  hotelStateSubmitted,
} from "../../../../store/slices/hotel/hotelSlice";
import { RootState } from "../../../../store/store";
import { toast } from "react-toastify";

import API from "../../../../Network/API";
import Network from "../../../../Network/Network";

import {
  endLoading,
  startLoading,
} from "../../../../store/slices/loader/loaderSlice";

interface FormTypes {
  areas: {
    _id: string;
    name: string;
    description: string;
  }[];
}

const AreaDetails = forwardRef(({}, ref) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { hotelId } = useParams();
  const hotelState = useSelector((state: RootState) => state?.hotel);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
    defaultValues: {
      areas: [
        { _id: new Types.ObjectId().toString(), name: "", description: "" },
      ],
    },
  });

  useImperativeHandle(ref, () => ({
    triggerHotelStep3FormValidation: async () => {
      if (hotelState?.is_step_3_complete) {
        const valid = await trigger();
        if (valid) {
          const updateHotelStateValue: any = {
            hotel_area: getValues("areas"),
          };

          dispatch(updateHotelState(updateHotelStateValue));
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  }));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "areas",
  });

  useEffect(() => {
    // @ts-ignore
    hotelState?.hotel_area?.length && setValue("areas", hotelState?.hotel_area);
  }, []);

  useEffect(() => {
    if (
      hotelState?.hotel_form_type === "add" &&
      hotelState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [hotelState?.hotel_form_type, hotelState?.is_all_state_reset]);

  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    dispatch(startLoading());
    const current_form_state = hotelState?.hotel_form_type;
    const final_area_data = data.areas.map((keys: any) => ({
      ...keys,
      area: keys.name,
    }));
    const updateHotelStateValue: any = {
      is_step_3_complete: true,
      hotel_area: getValues("areas"),
    };

    dispatch(updateHotelState(updateHotelStateValue));

    const final_data = {
      id: hotelId || hotelState._id ? hotelId || hotelState._id : null,
      name: hotelState.hotel_name,
      address: hotelState?.hotel_address,
      phone_no_primary: hotelState?.hotel_primary_phone_no,
      phone_no_secondary: hotelState?.hotel_secondary_phone_no,
      email_primary: hotelState?.hotel_primary_email,
      email_secondary: hotelState?.hotel_secondary_email,
      areas: final_area_data,
    };

    try {
      const response: any = await Network.post(API.addUpdateHotel, final_data);
      if (hotelState._id) {
        toast.success("Building details updated");
      } else {
        toast.success("Building details added");
      }
      if (current_form_state === "add") {
        const updateHotelId: any = { _id: response.data._id as string };
        dispatch(updateHotelState(updateHotelId));
        dispatch(endLoading());
        navigate("/hotel-management/add?step=4");
      } else {
        dispatch(hotelStateSubmitted());
        dispatch(endLoading());
        navigate(`/hotel-management`);
      }
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">Please enter area details</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <Fragment key={field?.id}>
            {(index !== 0 && <hr className="dashed pb-2" />) || null}
            <div className="mb-4">
              <Controller
                name={`areas.${index}.name`}
                control={control}
                defaultValue={field.name}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppInput
                    label="Area/Room Name"
                    isRequired
                    value={value || ""}
                    onChange={onChange}
                  />
                )}
              />
              {errors?.areas?.[index]?.name?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <Controller
                name={`areas.${index}.description`}
                control={control}
                defaultValue={field.description}
                rules={{
                  required: false,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppTextarea
                    label="Description"
                    value={value || ""}
                    onChange={onChange}
                  />
                )}
              />
              {errors?.areas?.[index]?.description?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
            <div className="d-flex align-items-center">
              {(index === fields?.length - 1 && (
                <AppButton
                  custom
                  className="button--add"
                  onClick={() =>
                    append({
                      _id: new Types.ObjectId().toString(),
                      name: "",
                      description: "",
                    })
                  }
                >
                  + Add Area
                </AppButton>
              )) ||
                null}
              {(fields?.length > 1 && (
                <AppButton
                  custom
                  className="button--remove ms-auto"
                  onClick={() => remove(index)}
                >
                  <img src={IconDelete} alt="Delete" /> Delete
                </AppButton>
              )) ||
                null}
            </div>
          </Fragment>
        ))}
        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Submit
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
});

export default AreaDetails;
