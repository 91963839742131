import React, { CSSProperties } from "react";
import AppButton from "./AppButton";
import IconEdit from "@/images/icon__edit.svg";
import IconTrash from "@/images/icon__trash.svg";
import IconView from "@/images/icon__password--show.svg";
import IconCalendar from "@/images/icon__calendar.svg";
import IconActivate from "@/images/icon__activate.svg";
import IconDeactivate from "@/images/icon__deactivate.svg";
import IconUnlink from "@/images/icon__unlink.svg";
interface AppTableActionType {
  row: any;
  onDelete?: any;
  onEdit?: any;
  disabled?: boolean;
  onView?: any;
  onDateAssign?: any;
  onUpdateStatus?: any;
  onUpdateStatusVisibility?: boolean;
  onUnlink?: any;
  onUnlinkVisibility?: boolean;
}

const disabledStyle: CSSProperties | any = {
  pointerEvents: "none",
  userSelect: "none",
  opacity: ".5",
};

const AppTableAction: React.FC<AppTableActionType> = ({
  row,
  onDelete,
  onEdit,
  disabled = false,
  onView,
  onDateAssign,
  onUpdateStatus,
  onUpdateStatusVisibility,
  onUnlink,
  onUnlinkVisibility,
}) => {
  return (
    <div
      className="button-tables button-group justify-content-md-center my-1 my-md-0"
      style={(disabled && disabledStyle) || null}
    >
      {(onView && (
        <AppButton custom onClick={() => onView(row)}>
          <img src={IconView} alt="" />
        </AppButton>
      )) ||
        null}
      {(onEdit && (
        <AppButton custom onClick={() => onEdit(row)}>
          <img src={IconEdit} alt="" />
        </AppButton>
      )) ||
        null}
      {(onDateAssign && (
        <AppButton custom onClick={() => onDateAssign(row)}>
          <img className="img-h-16" src={IconCalendar} alt="" />
        </AppButton>
      )) ||
        null}
      {(onDelete && (
        <AppButton custom onClick={() => onDelete(row)}>
          <img src={IconTrash} alt="" />
        </AppButton>
      )) ||
        null}
      {(onUpdateStatusVisibility && onUpdateStatus && (
        <AppButton custom onClick={() => onUpdateStatus(row)}>
          {(row?.is_active && (
            <img className="img-h-20" src={IconDeactivate} alt="" />
          )) || <img className="img-h-20" src={IconActivate} alt="" />}
        </AppButton>
      )) ||
        null}
      {(onUnlinkVisibility && onUnlink && (
        <AppButton custom onClick={() => onUnlink(row)}>
          <img className="img-h-12" src={IconUnlink} alt="" />
        </AppButton>
      )) ||
        null}
    </div>
  );
};

export default AppTableAction;
