import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import IconSearch from "@/images/icon__search.svg";
import IconTimes from "@/images/icon__times.svg";

interface AppSearchType {
  className?: string;
  value: string;
  onChangeSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const AppSearch: React.FC<AppSearchType> = ({
  className,
  value,
  onChangeSearchTerm,
}) => {
  const [searchTerm, setSearchTerm] = useState(value || "");
  useEffect(() => {
    setSearchTerm(value || "");
  }, [value]);
  const onSearchReset = () => {
    setSearchTerm("");
    onChangeSearchTerm("");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    onChangeSearchTerm(event.target.value);
  };
  return (
    <div
      className={`form-floating action single ${!!className ? className : ""}`}
    >
      <DebounceInput
        value={searchTerm}
        minLength={1}
        debounceTimeout={300}
        onChange={(e) => onChange(e)}
        placeholder="Search..."
        className="form-control"
      />
      <label>Search</label>
      {(!!searchTerm && (
        <div className="form-actions">
          <span onClick={onSearchReset}>
            <img src={IconTimes} alt="" />
          </span>
        </div>
      )) || (
        <div className="form-actions">
          <span>
            <img src={IconSearch} alt="" />
          </span>
        </div>
      )}
    </div>
  );
};

export default AppSearch;
