import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../../components/AppInput";
import AppButton from "../../../components/AppButton";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { toast } from "react-toastify";

interface ModalTypes {
  show: boolean;
  onClose: (refresh?: boolean) => void;
  data: any;
}

interface FormTypes {
  name: string;
}

const EditCameraModal: React.FC<ModalTypes> = ({ show, onClose, data }) => {
  const {
    control,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm<FormTypes>();

  useEffect(() => {
    setValue("name", data?.name);
  }, [data?._id, data?.name]);

  const onSubmit: SubmitHandler<FormTypes> = (u_data) => {
    const submitData = {
      _id: data._id,
      name: u_data.name,
    };
    try {
      Network.post(API.updateCamera, submitData)
        .then((resp: any) => {
          resp.status === 200
            ? toast.success("Camera name updated successfully!")
            : toast.error("Something went wrong!");
        })
        .catch((error: any) => {
          console.error(error);
          toast.error("Something went wrong!");
        });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong!");
    }
    onClose(true);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Camera</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: false,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Camera Name"
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
        </Modal.Body>
        <Modal.Footer>
          <AppButton type="submit" className="primary">
            Save
          </AppButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditCameraModal;
