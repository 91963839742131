import React from "react";
import Graphics from "@/images/graphics__hotelRegistrationSuccess.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userRegistrationFormStateSubmitted } from "../../../../store/slices/user/userRegistrationFormSlice";

const RegistrationCompleted: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <img className="mb-4 mb-md-5" src={Graphics} alt="" />
        <h4 className="mb-1">Thank you for your registration!</h4>
        <p className="color-light">Your details successfully saved</p>
        <Link
          className="back"
          to={"/user-management"}
          onClick={() => dispatch(userRegistrationFormStateSubmitted())}
        >
          Back to Dashbaoard
        </Link>
      </div>
    </>
  );
};

export default RegistrationCompleted;
