import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface pageState {
  title?: string;
  classes?: string;
  showNavigation?: boolean;
  backURL?: string;
}

const initialState: pageState = {
  title: "",
  classes: "",
  showNavigation: false,
  backURL: ''
};

export const pageSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    upsertPage: (state, action: PayloadAction<pageState>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const { upsertPage } = pageSlice.actions;
export default pageSlice.reducer;
