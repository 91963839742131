import { FC } from "react";
import IconPlaceholder from "@/images/icon__camera-placeholder.svg";

interface CameraPlaceholder {
  type: string;
}

const iconStyle = {
  width: "10rem",
  height: "10rem",
  borderRadius: "50%",
};

const CameraPlaceholder: FC<CameraPlaceholder> = ({ type }) => {
  return (
    <div className="d-flex flex-column align-items-center p-5">
      <span className="bg-background--light d-flex mb-4" style={iconStyle}>
        <img src={IconPlaceholder} className="m-auto" alt="" />
      </span>
      <h5 className="m-0">No {type} Cameras</h5>
    </div>
  );
};

export default CameraPlaceholder;
