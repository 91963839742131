import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import Dashboard from "./pages/dashboard/Dashboard";
import NotFound from "./pages/notFound/NotFound";
import SignIn from "./pages/authentication/SignIn";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import CameraManagement from "./pages/dashboard/camera/CameraManagement";
import UserManagement from "./pages/dashboard/user/UserManagement";
import HotelManagement from "./pages/dashboard/hotel/HotelManagement";
import PrivateRoute from "./utils/PrivateRoute";
import AddHotelDashboard from "./pages/dashboard/hotel/add/Dashboard";
import AddUserDashboard from "./pages/dashboard/user/add/Dashboard";
import Task from "./pages/dashboard/task/Task";
import TaskAssignment from "./pages/dashboard/task-assignment/TaskAssignment";
import CameraListing from "./pages/dashboard/camera/CameraListing";
import RegisteredCameras from "./pages/dashboard/camera/RegisteredCameras";
import CameraDetails from "./pages/dashboard/camera/CameraDetails";
import RegisterCameraDashboard from "./pages/dashboard/camera/register/Dashboard";
import ResetPassword from "./pages/authentication/ResetPassword";
import LinkedExpired from "./pages/linkExpired/LinkedExpired";
import RegisteredCameraDetails from "./pages/dashboard/camera/register/RegisteredCameraDetails";
import GetStarted from "./pages/dashboard/get-started/GetStarted";
import SOSIncident from "./pages/sosincident/SOSIncident";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path="/sos-incident/:id" element={<SOSIncident />} />

      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:userId" element={<ResetPassword />} />
      <Route path="link-expired" element={<LinkedExpired />} />

      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Dashboard />}>
          <Route
            index
            path="/camera-management"
            element={<CameraManagement />}
          />
          <Route path="/camera-management/lists" element={<CameraListing />} />
          <Route
            path="/camera-management/registered-cameras"
            element={<RegisteredCameras />}
          />
          <Route
            path="/camera-management/add"
            element={<RegisterCameraDashboard />}
          />
          <Route
            path="/camera-management/view/:cameraId"
            element={<RegisteredCameraDetails />}
          />
          <Route
            path="/camera-management/details/:cameraId"
            element={<CameraDetails />}
          />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/user-management/add" element={<AddUserDashboard />} />
          <Route
            path="/user-management/edit/:userId"
            element={<AddUserDashboard />}
          />
          <Route path="/hotel-management" element={<HotelManagement />} />
          <Route path="/hotel-management/add" element={<AddHotelDashboard />} />
          <Route
            path="/hotel-management/edit/:hotelId"
            element={<AddHotelDashboard />}
          />
          <Route path="/task" element={<Task />} />
          <Route path="/task-assignment" element={<TaskAssignment />} />
          <Route path="/getting-started" element={<GetStarted />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const AppRoutes: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
