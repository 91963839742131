import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import AppInput from "../../../../components/AppInput";
import AppSelect from "../../../../components/AppSelect";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import Network from "../../../../Network/Network";
import API from "../../../../Network/API";
import { toast } from "react-toastify";
import {
  endLoading,
  startLoading,
} from "../../../../store/slices/loader/loaderSlice";

type SelectType = { label: string; value: string };

type Props = {
  onRegisteringCamera: (data: any) => void;
};

interface FormTypes {
  name: string;
  hotel: SelectType[] | undefined | string;
  serial_no: string;
  device_type: SelectType | undefined | string;
}

const RegisterCameraDetails: React.FC<Props> = ({ onRegisteringCamera }) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormTypes>();
  const [allhotels, setAllHotels] = useState([]);

  useEffect(() => {
    getAllHotels();
  }, []);
  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    dispatch(startLoading());
    try {
      const resp = await Network.post(API.registerCamera, {
        ...data,
        //@ts-ignore
        hotel: data?.hotel?.value,
        //@ts-ignore
        device_type: data?.device_type?.value,
      });
      onRegisteringCamera(resp.data.cameraData);
      dispatch(endLoading());
    } catch (e: any) {
      toast.error("error occured");
      dispatch(endLoading());
    }
  };

  const getAllHotels = async () => {
    try {
      const hotels = await Network.get(API.hotelList);
      setAllHotels(
        hotels.data.hotels?.map((item: any) => ({
          label: item.name,
          value: item._id,
        }))
      );
    } catch (error) {
      toast.error("error occured");
    }
  };
  return (
    <>
      <div className="row h-md-100">
        <div className="col-md-12 d-flex align-items-center">
          <div className="row flex-grow-1 justify-content-center">
            <div className="col-md-6">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="text-center pb-2">Camera Details</h3>
                <div className="mb-4">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: false,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <AppInput
                        label="Camera Name"
                        value={value || ""}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <div className="mb-4">
                  <Controller
                    name="hotel"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <AppSelect
                        label="Building"
                        options={allhotels}
                        value={value || ""}
                        onChange={onChange}
                        isRequired
                      />
                    )}
                  />
                  {errors?.hotel?.type === "required" && (
                    <span className="form-status error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <Controller
                    name="device_type"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <AppSelect
                        label="Device Type"
                        options={[
                          { label: "Mobile", value: "mobile" },
                          { label: "Body Camera", value: "camera" },
                        ]}
                        value={value || ""}
                        onChange={onChange}
                        isRequired
                      />
                    )}
                  />
                  {errors?.device_type?.type === "required" && (
                    <span className="form-status error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="mb-0">
                  <Controller
                    name="serial_no"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                    }}
                    render={({ field: { value, onChange } }) => (
                      <AppInput
                        label="Serial No."
                        value={value || ""}
                        onChange={onChange}
                        isRequired
                      />
                    )}
                  />
                  {errors?.serial_no?.type === "required" && (
                    <span className="form-status error">
                      This field is required
                    </span>
                  )}
                </div>
                <div className="d-flex mt-4 pt-2">
                  <AppButton
                    custom
                    type="submit"
                    className="button--registration ms-auto"
                  >
                    Generate
                    <img src={IconNext} alt="" className="ms-2" />
                  </AppButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterCameraDetails;
