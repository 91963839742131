import React from "react";

interface AppButtonTypes {
  className?: string;
  id?: string;
  custom?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
}

const AppButton: React.FC<AppButtonTypes> = ({
  className,
  id,
  custom,
  children,
  disabled,
  type,
  ...props
}) => {
  return (
    <button
      id={id}
      className={`button ${
        !custom
          ? `${!!className ? className : ""}`
          : `custom ${!!className ? className : ""}`
      } ${(disabled && "disabled") || ""}`}
      disabled={disabled}
      type={type || "button"}
      {...props}
    >
      {children}
    </button>
  );
};

export default AppButton;
