import React from "react";
import { toast } from "react-toastify";
import IconArrow from "@/images/icon__formnext.svg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { useNavigate, useParams } from "react-router-dom";
import Main from "./Main";
import { passwordRegex } from "../../utils/Regex";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import API from "../../Network/API";
import Network from "../../Network/Network";

import {
  endLoading,
  startLoading,
} from "../../store/slices/loader/loaderSlice";

interface ChangePasswordFormTypes {
  password: string;
  confirm_password: string;
}

const ResetPassword: React.FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userId } = useParams();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChangePasswordFormTypes>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ChangePasswordFormTypes> = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.resetPassword, { ...data, id: userId });
      toast.success("Password updated successfully");
      dispatch(endLoading());
      navigate("/");
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
      console.error("e", e);
    }
  };

  return (
    <>
      <Main>
        <h1 className="mb-2">{title}</h1>
        <p className="color-light text--lrg mb-4 pb-md-2">
          Enter a new password
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: passwordRegex,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="New Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

            {errors?.password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.password?.type === "pattern" && (
              <span className="form-status error">
                The password must contain at least 1 lowercase alphabetical
                character, 1 uppercase alphabetical character, 1 numeric
                character, 1 special character, between 8 - 16 character.
              </span>
            )}
          </div>

          <div className="mb-4 pb-md-2">
            <Controller
              name="confirm_password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: (value: any) => {
                  if (value !== watch("password")) {
                    return "Password and confirm password must be same";
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Confirm New Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

            {errors?.confirm_password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}

            {errors?.confirm_password?.type === "validate" && (
              <span className="form-status error">
                {errors?.confirm_password?.message}
              </span>
            )}
          </div>

          <div className="d-flex justify-content-center">
            <AppButton custom type="submit">
              Save
              <img src={IconArrow} className="ms-3" alt="" />
            </AppButton>
          </div>
        </form>
      </Main>
    </>
  );
};

export default ResetPassword;
