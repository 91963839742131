import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../components/AppButton";
import CameraInfo from "./CameraInfo";
import IconArrowRight from "@/images/icon__arrow--right.svg";
import CameraSOS from "./CameraSOS";
import CameraActive from "./CameraActive";
import { Link, useNavigate } from "react-router-dom";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { updateAllCamera } from "../../../store/slices/camera/cameraSlice";
import { RootState } from "../../../store/store";
import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";
import CameraPlaceholder from "./CameraPlaceholder";

const CameraManagement: React.FC = () => {
  const userDetails = useSelector((store: any) => store.user);
  const { allCameras } = useSelector((store: RootState) => store.camera);
  // const [searchTerm, setSearchTerm] = useState("");
  const [activeCameras, setActiveCameras] = useState<number>(0);
  const [totalCameras, setTotalCameras] = useState<number>(0);
  const [sosCameras, setSOSCameras] = useState<number>(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCameras();
  }, []);

  useEffect(() => {
    if (allCameras.length > 0) {
      let active = 0;
      let sos = 0;

      allCameras.forEach((camera: any) => {
        if (camera.status === "play" && !camera.sos) {
          active++;
        }
        if (camera.sos) {
          sos++;
        }
      });
      setActiveCameras(active);
      setSOSCameras(sos);
    }
  }, [allCameras]);

  const fetchCameras = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchCameras, {})
        .then((resp: any) => {
          let totalActive = 0;
          let totalSOS = 0;
          resp.data.cameras.forEach((camera: any) => {
            if (camera.status === "play") {
              totalActive++;
            }
            if (camera.sos) {
              totalSOS++;
            }
          });
          setActiveCameras(totalActive);
          setSOSCameras(totalSOS);
          setTotalCameras(resp.data.total_count || 0);
          // setAllCameras(resp.data.cameras || []);
          dispatch(updateAllCamera(resp.data.cameras || []));
          dispatch(endLoading());
        })
        .catch(() => {
          dispatch(endLoading());
          console.error;
        });
    } catch (error) {
      dispatch(endLoading());
      console.error(error);
    }
  };

  const getGreetingTime = (currentTime: any) => {
    if (!currentTime || !currentTime.isValid()) {
      return "Hello";
    }

    const splitAfternoon = 12;
    const splitEvening = 17;
    const currentHour = parseFloat(currentTime.format("HH"));

    if (currentHour >= splitAfternoon && currentHour < splitEvening) {
      return "Good Afternoon";
    } else if (currentHour >= splitEvening) {
      return "Good Evening";
    }
    return "Good Morning";
  };

  return (
    <>
      <div className="d-lg-flex align-items-center mb-4">
        <h1 className="mb-3 m-lg-0">
          {getGreetingTime(moment())} <br className="d-inline d-md-none" />
          {userDetails?.name}
        </h1>
        <div className="d-md-flex align-items-center ms-auto">
          {/* <AppSearch
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
            className="flex-grow-1 w-100 w-md-auto"
          /> */}
          {/* ms-md-3 mt-3 mt-md-0 */}
          <AppButton
            className="primary  w-100 w-md-auto flex-shrink-0"
            onClick={() => navigate("/camera-management/add")}
          >
            + Add Camera
          </AppButton>
        </div>
      </div>
      <CameraInfo
        totalCameras={totalCameras}
        activeCameras={activeCameras}
        sosCameras={sosCameras}
      />
      {sosCameras > 0 && (
        <div className="cameras sos">
          <div className="cameras-header">
            <h3 className="m-0">SOS Alert</h3>
            <Link
              className="cameras-header__action"
              to={sosCameras > 0 ? "/camera-management/lists?type=sos" : "#"}
            >
              {sosCameras} Alert(s) <img src={IconArrowRight} alt="" />
            </Link>
          </div>
          <div className="row">
            {allCameras.map(
              (camera) =>
                camera.sos && (
                  <div className="col-md-4 my-2" key={camera._id}>
                    <CameraSOS
                      streamName={camera.channel_name as string}
                      cleanerId={camera?.user?._id}
                      cleanerName={camera.user?.name || camera.user?.email}
                      cameraManufacturer={camera?.device_manufacturer}
                      cameraModel={camera?.device_model}
                      deviceType={camera?.device_type}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      )}
      <div className="cameras active">
        <div className="cameras-header">
          <h3 className="m-0">In-Use Cameras</h3>
          <Link
            className="cameras-header__action"
            to={
              activeCameras > 0 ? "/camera-management/lists?type=active" : "#"
            }
          >
            {activeCameras} Camera(s) <img src={IconArrowRight} alt="" />
          </Link>
        </div>
        {(activeCameras > 0 && (
          <div className="row">
            {allCameras.map(
              (camera) =>
                camera.status === "play" &&
                !camera.sos &&
                camera?.user?._id && (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3 my-2"
                    key={camera.channel_name}
                  >
                    <CameraActive
                      streamName={camera.channel_name as string}
                      cleanerId={camera?.user?._id}
                      cleanerName={camera.user?.name || camera.user?.email}
                      cameraManufacturer={camera?.device_manufacturer}
                      cameraModel={camera?.device_model}
                      deviceType={camera?.device_type}
                    />
                  </div>
                )
            )}
          </div>
        )) || <CameraPlaceholder type="Active" />}
      </div>
    </>
  );
};

export default CameraManagement;
