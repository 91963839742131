import React, { useEffect, useRef } from "react";
import AppButton from "../../../components/AppButton";
import IconEnlarge from "@/images/icon__enlarge.svg";
// import IconMute from "@/images/icon__mute.svg";
// import IconPhone from "@/images/icon__phone.svg";
import { Link } from "react-router-dom";
import { getVideoStreamURL } from "../../../utils/hls";
import moment from "moment";
import { cameraManufacturers } from "../../../utils/constant";

type Props = {
  streamName: string;
  cleanerId: string;
  cleanerName: string;
  cameraManufacturer: string | null;
  cameraModel: string | null;
  deviceType: string | null;
};

const CameraActive: React.FC<Props> = ({
  streamName,
  cleanerId,
  cleanerName,
  cameraManufacturer,
  cameraModel,
  deviceType,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    getVideoStreamURL(streamName, videoRef);
  }, [streamName]);

  return (
    <div className="block">
      <div className="block__body">
        <div className="video">
          <video
            ref={videoRef}
            // controls={false}
            // autoplay
            style={{
              rotate: !deviceType
                ? cameraManufacturer?.toLowerCase() === "motorola"
                  ? cameraModel === "V500" || cameraModel === "VB400"
                    ? "0deg"
                    : "90deg"
                  : cameraManufacturers.includes(
                      cameraManufacturer?.toLowerCase() || ""
                    )
                  ? "0deg"
                  : "90deg"
                : deviceType === "mobile"
                ? "90deg"
                : "0deg",
            }}
          />
        </div>
        <div className="w-100 d-flex align-items-center">
          <span className="live">Live</span>
          <span className="ms-auto">{cleanerName}</span>
        </div>
        <span className="mt-auto w-100 d-flex align-items-center">
          {/* Room 308 <br /> */}
          <span>{moment().format("MMMM DD, YYYY")}</span>
          <span className="ms-auto">{moment().format("hh:mm a")}</span>
        </span>
      </div>
      <div className="block__footer">
        <AppButton custom className="enlarge">
          <img src={IconEnlarge} alt="" className="me-2" /> Enlarge View
        </AppButton>
        {/* <div className="ms-auto button-group">
          <AppButton custom>
            <img src={IconMute} alt="" />
          </AppButton>
          <AppButton custom className="ms-2">
            <img src={IconPhone} alt="" />
          </AppButton>
        </div> */}
      </div>
      <Link
        className="stretched-link"
        to={`/camera-management/details/${streamName}?cleanerId=${cleanerId}&type=active`}
      />
    </div>
  );
};

export default CameraActive;
