import { jwtDecode } from "jwt-decode";

function isRefreshTokenExpired (refreshToken: string)  {
    const decoded: any = jwtDecode(refreshToken);  
    if (Date.now() >= decoded.exp * 1000) {     
        console.log('true');
           
        return true;
    }
    return false;
};

export default isRefreshTokenExpired