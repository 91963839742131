import React from "react";
import { Modal } from "react-bootstrap";
import AppButton from "../components/AppButton";

interface ModalTypes {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  type?: "activate" | "deactivate";
}

const AppDeleteConfirmationModal: React.FC<ModalTypes> = ({
  show,
  onClose,
  onConfirm,
  type = "deactivate",
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {(type === "activate" && "Activate") || "Deactivate"} Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4">
        Do you really want to{" "}
        {(type === "activate" && "activate") || "deactivate"} this record?
      </Modal.Body>
      <Modal.Footer>
        <AppButton type="submit" className="primary" onClick={onConfirm}>
          {(type === "activate" && "Activate") || "Deactivate"}
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AppDeleteConfirmationModal;
