export const API = {
  login: `/login`,
  refreshToken: `/regenerate-tokens`,
  hotelList: `/fetch-hotels`,
  addUpdateHotel: `/upsert-hotel`,
  fetchHotelDataById: `/fetch-hotel-by-id`,
  fetchRoles: `/fetch-roles`,
  userList: `/fetch-users`,
  addUser: `/register-user`,
  updateUser: `/update-user`,
  fetchUserDataById: `/fetch-user-by-id`,
  deactivateHotel: `/delete-hotel`,
  activateHotel: `/activate-hotel`,
  uploadImage: `/upload-image`,
  taskList: `/fetch-tasks`,
  createUpdateTask: `/upsert-task`,
  fetchTaskById: `/fetch-task-by-id`,
  sendForgotPasswordEmail: `/send-forgot-password-email`,
  resetPassword: `/reset-password`,
  registerCamera: "/register-camera",
  fetchCameras: "/fetch-cameras",
  fetchSpecificCameraDetails: "/fetch-camera-by-id",
  updateCamera: "/update-camera",
  deactivateCamera: "/deactivate-camera",
  activateCamera: "/activate-camera",
  upsertTaskAssignment: "/upsert-task-assignment",
  fetchTaskAssignments: "/fetch-task-assignments",
  fetchTaskAssignmentById: "/fetch-task-assignment-by-id",
  activateTask: "/activate-task",
  deactivateTask: "/deactivate-task",
  activateUser: "/activate-user",
  deactivateUser: "/deactivate-user",
  getStreamingKeys: "/streaming-keys",
  fetchAssignments: "/fetch-assignments",
  getHLSStreamingSessionURL: "/fetch-hls-stream",
  unlinkCamera: "/delink-camera",
  fetchSOSIncidentById: "/fetch-sos-incident-by-id",
  changePassword: "/change-password",
  endSos: "/switch-sos",
};

export default API;
