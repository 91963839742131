import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import AppSearch from "../../../components/AppSearch";
import { Dropdown } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import IconFilter from "@/images/icon__filter.svg";
import moment from "moment";
import AppTableAction from "../../../components/AppTableAction";
import AppDeleteConfirmationModal from "../../../shared/AppDeleteConfirmationModal";
import AppTable from "../../../components/AppTable";
import { AppTableExpandRowSettings } from "../../../utils/AppTableExpandSettings";
import EditCameraModal from "./EditCameraModal";
import { useNavigate } from "react-router-dom";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { filterOptions, cameraSortOptions } from "../../../utils/constant";
import { toast } from "react-toastify";
import { backIcon } from "../../../layout/Header";
import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";
import IconClearFilter from "@/images/icon__clear-filter.svg";

const RegisteredCameras: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { title, backURL } = useSelector((state: RootState) => state?.page);

  const [searchTerm, setSearchTerm] = useState("");
  const [showEditCameraModal, setShowEditCameraModal] = useState(false);
  const [editCameraData, setEditCameraData] = useState({});
  const [cameras, setCameras] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setFilterOption] = useState(true);
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [showUpdateStatusConfirmModal, setShowUpdateStatusModal] =
    useState(false);
  const [toBeUpdatedCamera, setToBeUpdatedCamera] = useState({
    camId: "",
    status: "",
  });

  const perPage = 10;

  const onChangeSearchTerm = (termSearched: any) => {
    setSearchTerm(termSearched);
  };

  const columns = [
    {
      dataField: "name",
      text: "Camera Name",
      headerAttrs: { title: "Sort By:" },
      attrs: { title: "Camera Name" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "hotel_name",
      text: "Building Name",
      attrs: { title: "Hotel Name" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => row?.hotel?.name,
    },
    {
      dataField: "serial_no",
      text: "Serial No.",
      attrs: { title: "Serial No." },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "camera_unique_id",
      text: "Unique Id",
      attrs: { title: "Unique Id" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "user",
      text: "User",
      attrs: { title: "User" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => (
        <>
          {row?.user
            ? row?.user?.name
              ? row?.user?.name
              : row?.user?.email
            : " - "}
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      attrs: { title: "Status" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <span className="bg-primary px-3 py-1 rounded color-white text--nowrap text-capitalize">
          {(row?.user?._id && "In Use") || "Idle"}
        </span>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      attrs: { title: "Action" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <>
          {row.is_active ? (
            <AppTableAction
              row={row}
              onView={() => handleViewCamera(row)}
              onEdit={() => handleEditCamera(row)}
              onUpdateStatus={() => {
                setToBeUpdatedCamera({ camId: row._id, status: "active" });
                setShowUpdateStatusModal(true);
              }}
              onUpdateStatusVisibility={!row?.user?._id}
              onUnlink={() => handleUnlinkCamera(row?._id)}
              onUnlinkVisibility={row?.user?._id}
            />
          ) : (
            <AppTableAction
              row={row}
              onUpdateStatus={() => {
                setToBeUpdatedCamera({ camId: row._id, status: "inactive" });
                setShowUpdateStatusModal(true);
              }}
              onUpdateStatusVisibility={!row?.user?._id}
            />
          )}
        </>
      ),
      headerStyle: {
        width: "200px",
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const expandRow = {
    ...AppTableExpandRowSettings,
    renderer: (row: any) => {
      return (
        <>
          <div className="row">
            <div className="col-md-4 mb-3">
              <h6 className="color-md-light mb-1">Registration Date:</h6>
              {moment(row?.createdAt).format("MMMM DD, YYYY") || "-"}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="color-md-light mb-1">Device Id:</h6>
              {row?.device_id || "-"}
            </div>
            <div className="col-md-4 mb-3">
              <h6 className="color-md-light mb-1">Device Name:</h6>
              {row?.device_name || "-"}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <h6 className="color-md-light mb-1">Channel Name:</h6>
              {row?.channel_name || "-"}
            </div>
            <div className="col-md-8">
              <h6 className="color-md-light mb-1">ARN:</h6>
              {row?.arn || "-"}
            </div>
          </div>
        </>
      );
    },
  };

  const fetchCameras = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchCameras, {
        sort: sortOption,
        page,
        limit: perPage,
        search: searchTerm,
        active: filterOption,
      })
        .then((resp: any) => {
          setCameras(resp.data.cameras || []);
          setTotal(resp.data.total_count || 0);
          dispatch(endLoading());
        })
        .catch(() => {
          dispatch(endLoading());
          console.error;
        });
    } catch (error) {
      dispatch(endLoading());
      console.error(error);
    }
  };

  const handleUpdateCameraStatus = async () => {
    setShowUpdateStatusModal(false);
    dispatch(startLoading());
    try {
      const response: any = await Network.get(
        `${
          toBeUpdatedCamera?.status === "active"
            ? API.deactivateCamera
            : API.activateCamera
        }`,
        { _id: toBeUpdatedCamera?.camId }
      );
      if (response !== undefined && response?.status === 200) {
        toast.success(response?.data?.message);

        setToBeUpdatedCamera({ camId: "", status: "" });
        fetchCameras();
        dispatch(endLoading());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
      dispatch(endLoading());
    }
  };

  const handleEditCamera = (data: any) => {
    setEditCameraData(data);
    setShowEditCameraModal(true);
  };

  const handleViewCamera = (data: any) => {
    if (data?.arn) {
      navigate(`/camera-management/view/${data?._id}`);
    } else {
      const cameraState = {
        camera_unique_id: data?.camera_unique_id,
        hotel: data?.hotel?._id,
        name: data?.name,
        serial_no: data?.serial_no,
      };
      navigate("/camera-management/add", { state: cameraState });
    }
  };

  useEffect(() => {
    fetchCameras();
  }, [page, searchTerm, sortOption, filterOption]);

  const handleResetFilter = () => {
    setShowResetIcon(false);
    setSortOption("");
    setFilterOption(true);
  };

  const handleUnlinkCamera = async (id: any) => {
    dispatch(startLoading());
    try {
      const response: any = await Network.get(API.unlinkCamera, {
        cameraId: id,
      });
      if (response !== undefined && response?.status === 200) {
        toast.success(response?.data?.message);
        fetchCameras();
        dispatch(endLoading());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong!");
      dispatch(endLoading());
    }
  };

  return (
    <>
      <h1 className="d-flex align-items-center">
        {(!!backURL && (
          <AppButton
            custom
            className="button--back d-md-none"
            onClick={() => navigate(backURL)}
          >
            {backIcon}
          </AppButton>
        )) ||
          null}
        {title}
      </h1>
      <div className="d-md-flex align-items-center mb-4">
        <AppSearch value={searchTerm} onChangeSearchTerm={onChangeSearchTerm} />
        <div className="ms-auto d-flex align-items-center mt-3 mt-md-0 me-md-3">
          <Dropdown align={"end"} className="w-50 w-md-auto">
            <Dropdown.Toggle className="w-100">
              {cameraSortOptions.find((option) => option.value === sortOption)
                ?.label || "Sort"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {cameraSortOptions.map((option) => (
                <Dropdown.Item
                  key={option?.value}
                  as={"button"}
                  onClick={() => {
                    setSortOption(option.value);
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={"end"} className="ms-3 w-50 w-md-auto">
            <Dropdown.Toggle className="has-icon w-100">
              {filterOptions.find((option) => option.value === filterOption)
                ?.label || "Filter"}{" "}
              <img src={IconFilter} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filterOptions.map((option) => (
                <Dropdown.Item
                  key={option?.label}
                  as={"button"}
                  onClick={() => {
                    setPage(1);
                    setFilterOption(option.value);
                    if (option.value === true) return;
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mt-3 mt-md-0">
          {(showResetIcon && (
            <AppButton className="danger me-3" onClick={handleResetFilter}>
              <img className="img-h-20" src={IconClearFilter} alt="" />
            </AppButton>
          )) ||
            null}
          <AppButton
            className="primary w-100 w-md-auto"
            onClick={() => navigate("/camera-management/add")}
          >
            + Add Camera
          </AppButton>
        </div>
      </div>
      <AppTable
        classes="no-filter"
        headerClasses="empty"
        keyField="_id"
        columns={columns}
        rows={cameras || []}
        expandRow={expandRow}
        pagination
        totalCount={total}
        onPageChange={setPage}
        perPage={perPage}
      />

      {(showUpdateStatusConfirmModal && (
        <AppDeleteConfirmationModal
          show={showUpdateStatusConfirmModal}
          onClose={() => setShowUpdateStatusModal(false)}
          onConfirm={handleUpdateCameraStatus}
          type={(filterOption && "deactivate") || "activate"}
        />
      )) ||
        null}

      {showEditCameraModal && (
        <EditCameraModal
          show={showEditCameraModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              fetchCameras();
            }
            setShowEditCameraModal(false);
            setEditCameraData({});
          }}
          data={editCameraData}
        />
      )}
    </>
  );
};

export default RegisteredCameras;
