import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppButton from "../../../components/AppButton";
import AppSelect from "../../../components/AppSelect";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { toast } from "react-toastify";
import moment from "moment";

interface ModalTypes {
  show: boolean;
  onClose: (refresh?: boolean) => void;
  time: moment.Moment;
  taskType: string;
  cleaner_id: string;
}

interface FormTypes {
  task: string;
}

const StartTask: React.FC<ModalTypes> = ({
  show,
  onClose,
  time,
  cleaner_id,
  taskType,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormTypes>();

  const [taskAssignments, setTaskAssignments] = useState([]);

  useEffect(() => {
    if (cleaner_id) {
      getAllTasks();
    }
  }, []);

  const getAllTasks = async () => {
    try {
      const resp = await Network.get(API.fetchAssignments, {
        task_type: taskType,
        cleaner_id: cleaner_id,
      });

      setTaskAssignments(
        resp.data.task_assignments.map((task: any) => {
          return {
            label: `${task.task.task} - ${task.area.area}${
              task.sub_area && task.sub_area !== "" ? ` - ${task.sub_area}` : ""
            }${
              task.room_no && task.room_no !== "" ? ` - ${task.room_no}` : ""
            }`,
            value: task._id,
          };
        })
      );
    } catch (error) {
      toast.error("error occured");
    }
  };

  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    const payload: any = {
      // @ts-ignore
      _id: data.task.value,
    };

    if (taskType === "end") {
      payload.end_time = moment(time).toISOString();
    } else if (taskType === "start") {
      payload.start_time = moment(time).toISOString();
    }

    try {
      await Network.post(API.upsertTaskAssignment, payload);
      onClose();
    } catch (error) {}
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Controller
            name="task"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <AppSelect
                label="Select Task"
                options={taskAssignments}
                value={value || ""}
                onChange={onChange}
                isRequired
              />
            )}
          />
          {errors?.task?.type === "required" && (
            <span className="form-status error">*This field is required</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <AppButton
            type="submit"
            className="primary"
            disabled={watch("task")?.length <= 0}
          >
            Save
          </AppButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default StartTask;
