import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";

interface AppPhoneInputType {
  className?: string;
  defaultCountry?: any;
  value: string;
  onChange: (e: string | any) => void;
  isRequired?: boolean;
  label: string;
}

const AppPhoneInput: React.FC<AppPhoneInputType> = ({
  className,
  defaultCountry,
  value,
  onChange,
  isRequired,
  label,
}) => {
  const [focusClass, setFocusClass] = useState<string | undefined | null>("");

  useEffect(() => {
    if (!!value) {
      setFocusClass("focused");
    } else {
      setFocusClass("");
    }
  }, [value]);

  const handleFocus = () => {
    if (!value) {
      setFocusClass("focused");
    }
  };

  const handleBlur = () => {
    if (!value) {
      setFocusClass("");
    }
  };

  return (
    <div
      className={`form-phone-input ${focusClass} ${
        !!className ? className : ""
      }`}
    >
      <PhoneInput
        defaultCountry={defaultCountry}
        placeholder={null}
        value={value}
        onChange={onChange}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
      />
      <label>
        {label} {(isRequired && <sup>*</sup>) || null}
      </label>
    </div>
  );
};

export default AppPhoneInput;
