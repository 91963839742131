import React, { useEffect } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import { toast } from "react-toastify";
import IconUpload from "@/images/icon__upload.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  updateUserRegistrationFormState,
  userRegistrationFormStateSubmitted,
} from "../../../../store/slices/user/userRegistrationFormSlice";
import { AppDispatch, RootState } from "../../../../store/store";
import API from "../../../../Network/API";
import Network from "../../../../Network/Network";
import IconTrash from "@/images/icon__trash.svg";
import { signIn } from "../../../../store/slices/user/userSlice";
import {
  endLoading,
  startLoading,
} from "../../../../store/slices/loader/loaderSlice";

interface FormTypes {
  image: any;
}

const ProfilePicture: React.FC = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const userFormState = useSelector(
    (state: RootState) => state?.userRegistrationForm
  );
  const { user } = useSelector((state: RootState) => state);
  let { userId } = useParams();

  //const maxSize = 10 * 1024 * 1024;

  const { control, setValue, handleSubmit, reset } = useForm<FormTypes>({
    mode: "onChange",
  });

  useEffect(() => {
    userFormState?.image && setValue("image", userFormState?.email);
  }, []);

  useEffect(() => {
    if (
      userFormState?.user_form_type === "add" &&
      userFormState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [userFormState?.user_form_type, userFormState?.is_all_state_reset]);

  const onSubmit: SubmitHandler<FormTypes> = async (_data) => {
    // if (data?.image[0]?.size > maxSize) {
    //   toast.error("Uploaded file size limit 10mb exceeded");
    //   return;
    // }
    dispatch(startLoading());
    const apiUrl =
      userFormState?.user_form_type === "add" ? API.addUser : API.updateUser;
    let assignedHotels;
    if (userFormState?.hotels && userFormState?.hotels?.length > 0) {
      assignedHotels = userFormState?.hotels?.map((e: any) => e.value);
    }

    const final_data: any = {
      _id: userId || userFormState._id ? userId || userFormState._id : null,
      name: userFormState.name,
      email: userFormState?.email,
      password: userFormState?.password,
      phone_no: userFormState?.phone_no,
      employee_id: userFormState?.employee_id,
      role: userFormState?.role?.value,
      first_login: userFormState?.first_login,
      image: userFormState?.image,
      hotels: assignedHotels,
    };

    if (!final_data?.password) {
      delete final_data.password;
    }

    try {
      const response: any = await Network.post(apiUrl, final_data);
      if (userFormState._id) {
        toast.success("User details updated");
      } else {
        toast.success("User registration completed");
      }

      if (userFormState?.user_form_type === "add") {
        const updateUserRegistrationFormStateValue: any = {
          _id: response.data._id as string,
          is_step_3_complete: true,
        };
        dispatch(
          updateUserRegistrationFormState(updateUserRegistrationFormStateValue)
        );
        dispatch(endLoading());
        navigate("/user-management/add?step=4");
      } else {
        if (user?.id === final_data._id) {
          const updateUser: any = {
            name: final_data.name,
            token: response.data.token || user.token,
            refresh_token: response.data.refresh_token || user.refresh_token,
          };
          dispatch(signIn(updateUser));
        }
        dispatch(userRegistrationFormStateSubmitted());
        dispatch(endLoading());
        navigate(`/user-management`);
      }
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
      console.error("e", e);
    }
  };

  const handleUploadImage = async (image: any) => {
    dispatch(startLoading());
    const formData: FormData = new FormData();
    const fileToUpload = image[0];
    formData.append("image", fileToUpload, fileToUpload.name);

    try {
      const response: any = await Network.post(API.uploadImage, formData);
      const updateUserRegistrationFormStateValue: any = {
        image: response?.data?.file_url,
      };
      dispatch(
        updateUserRegistrationFormState(updateUserRegistrationFormStateValue)
      );
      dispatch(endLoading());
    } catch (e) {
      toast.error("can not uplaod file.");
      dispatch(endLoading());
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">Upload your profile picture</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        {(userFormState?.image && (
          <div className="upload success">
            <div className="avatar">
              <img src={userFormState?.image} alt="" />
            </div>
            <AppButton
              custom
              onClick={() => {
                const updateUserRegistrationFormStateValue: any = {
                  image: "",
                };
                dispatch(
                  updateUserRegistrationFormState(
                    updateUserRegistrationFormStateValue
                  )
                );
              }}
            >
              <img src={IconTrash} alt="" />
            </AppButton>
          </div>
        )) || (
          <div className="m-0">
            <Controller
              name="image"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: {} }) => (
                <div className="upload">
                  <input
                    type="file"
                    onChange={(e) => {
                      handleUploadImage(e.target.files);
                      e.target.value = "";
                    }}
                    accept="image/jpeg, image/png"
                  />
                  <div className="d-flex justify-content-center mb-4">
                    <img src={IconUpload} alt="" />
                  </div>
                  <h4 className="mb-1">Choose a file or drag & drop it here</h4>
                  <p className="color-light mb-4">
                    JPG, JPEG or PNG up to 10MB
                  </p>
                  <span className="button primary">Browse File</span>
                </div>
              )}
            />
          </div>
        )}
        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Submit
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
};

export default ProfilePicture;
