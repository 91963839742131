import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "./slices/user/userSlice";
import pageSlice from "./slices/page/pageSlice";
import hotelSlice from "./slices/hotel/hotelSlice";
import userRegistrationFormSlice from "./slices/user/userRegistrationFormSlice";
import cameraSlice from "./slices/camera/cameraSlice";
import loaderSlice from "./slices/loader/loaderSlice";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userSlice,
  page: pageSlice,
  hotel: hotelSlice,
  userRegistrationForm: userRegistrationFormSlice,
  camera: cameraSlice,
  loader: loaderSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
