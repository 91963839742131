import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AppSearch from "../../../components/AppSearch";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AppButton from "../../../components/AppButton";
import IconFilter from "@/images/icon__filter.svg";
import { RootState } from "../../../store/store";
import AppTableAction from "../../../components/AppTableAction";
import AppTable from "../../../components/AppTable";
import AddTaskModal from "./add/AddTaskModal";
import AppDeleteConfirmationModal from "../../../shared/AppDeleteConfirmationModal";
import API from "../../../Network/API";
import Network from "../../../Network/Network";
import { sortOptions, filterOptions } from "../../../utils/constant";
import IconClearFilter from "@/images/icon__clear-filter.svg";

import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";
import { AppTableExpandRowSettings } from "../../../utils/AppTableExpandSettings";

const Task: React.FC = ({}) => {
  const { title } = useSelector((state: RootState) => state?.page);
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [taskModalType, setTaskModalType] = useState("Add");
  const [taskModalData, setTaskModalData] = useState();
  const [showUpdateStatusConfirmModal, setShowUpdateStatusModal] =
    useState(false);

  const [page, setPage] = useState(1);
  const [allTasks, setAllTasks] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setFilterOption] = useState(true);
  const [toBeUpdatedTask, setToBeUpdatedTask] = useState({
    taskId: "",
    status: "",
  });
  const [showResetIcon, setShowResetIcon] = useState(false);

  const perPage = 10;

  const onChangeSearchTerm = (termSearched: any) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const getAllTasks = async () => {
    dispatch(startLoading());
    try {
      const tasks = await Network.get(API.taskList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortOption,
        active: filterOption,
      });
      setAllTasks(tasks.data.tasks);
      setTotalCount(tasks.data.total_count);
      dispatch(endLoading());
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getAllTasks();
  }, [page, searchTerm, sortOption, filterOption]);

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + "...";
  };

  const columns = [
    {
      dataField: "hotel_name",
      text: "Building ",
      headerAttrs: { title: "Sort By:" },
      attrs: { title: "Building " },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => <>{row?.hotel?.name}</>,
    },
    {
      dataField: "area_name",
      text: "Area Name",
      attrs: { title: "Area Name" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => <>{row?.area?.area}</>,
    },
    {
      dataField: "task",
      text: "Task",
      attrs: { title: "Task" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => (
        <>{(row?.task && truncate(row.task, 50)) || "-"}</>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      attrs: { title: "Action" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => {
        return (
          <>
            {row.is_active ? (
              <AppTableAction
                row={row}
                onEdit={() => handleEditTask(row)}
                onUpdateStatus={() => {
                  setToBeUpdatedTask({ taskId: row._id, status: "active" });
                  setShowUpdateStatusModal(true);
                }}
              />
            ) : (
              <AppTableAction
                row={row}
                onUpdateStatus={() => {
                  setToBeUpdatedTask({ taskId: row._id, status: "inactive" });
                  setShowUpdateStatusModal(true);
                }}
              />
            )}
          </>
        );
      },
      headerStyle: {
        width: "120px",
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const expandRow = {
    ...AppTableExpandRowSettings,
    renderer: (row: any) => {
      return (
        <>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h6 className="color-md-light mb-1">Task:</h6>
              {row?.task || "-"}
            </div>
          </div>
        </>
      );
    },
  };

  const onAddTask = () => {
    setTaskModalType("Add");
    setShowAddTaskModal(true);
  };

  const OnAddOrEditTask = (afterSaveStatus: any) => {
    setTaskModalType("Add");
    setShowAddTaskModal(false);
    if (afterSaveStatus === 200) {
      getAllTasks();
    }
  };

  const handleEditTask = async (data: any) => {
    try {
      const response: any = await Network.get(API.fetchTaskById, {
        taskId: data._id,
      });

      setTaskModalData(response?.data?.task);
      setTaskModalType("Edit");
      setShowAddTaskModal(true);
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  const handleUpdateTaskStatus = async () => {
    setShowUpdateStatusModal(false);
    dispatch(startLoading());
    try {
      const response: any = await Network.get(
        `${
          toBeUpdatedTask?.status === "active"
            ? API.deactivateTask
            : API.activateTask
        }`,
        { taskId: toBeUpdatedTask?.taskId }
      );
      if (response !== undefined && response?.status === 200) {
        toast.success(response?.data?.message);

        setToBeUpdatedTask({ taskId: "", status: "" });
        getAllTasks();
        dispatch(endLoading());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      dispatch(endLoading());
    }
  };

  const handleResetFilter = () => {
    setShowResetIcon(false);
    setSortOption("");
    setFilterOption(true);
  };

  return (
    <>
      <h1>{title}</h1>
      <div className="d-md-flex align-items-center mb-4">
        <AppSearch value={searchTerm} onChangeSearchTerm={onChangeSearchTerm} />
        <div className="ms-auto d-flex align-items-center mt-3 mt-md-0 me-md-3">
          <Dropdown align={"end"} className="w-50 w-md-auto">
            <Dropdown.Toggle className="w-100">
              {sortOptions.find((option) => option.value === sortOption)
                ?.label || "Sort"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {sortOptions.map((option) => (
                <Dropdown.Item
                  key={option?.value}
                  as={"button"}
                  onClick={() => {
                    setSortOption(option.value);
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={"end"} className="ms-3 w-50 w-md-auto">
            <Dropdown.Toggle className="has-icon w-100">
              {filterOptions.find((option) => option.value === filterOption)
                ?.label || "Filter"}{" "}
              <img src={IconFilter} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filterOptions.map((option) => (
                <Dropdown.Item
                  key={option?.label}
                  as={"button"}
                  onClick={() => {
                    setPage(1), setFilterOption(option.value);
                    if (option.value === true) return;
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mt-3 mt-md-0">
          {(showResetIcon && (
            <AppButton className="danger me-3" onClick={handleResetFilter}>
              <img className="img-h-20" src={IconClearFilter} alt="" />
            </AppButton>
          )) ||
            null}
          <AppButton className="primary w-100 w-md-auto" onClick={onAddTask}>
            + Add Task
          </AppButton>
        </div>
      </div>
      <AppTable
        classes="no-filter"
        headerClasses="empty"
        keyField="_id"
        columns={columns}
        rows={allTasks || []}
        expandRow={expandRow}
        pagination
        totalCount={totalCount}
        perPage={perPage}
        page={page}
        onPageChange={setPage}
      />
      {(showAddTaskModal && (
        <AddTaskModal
          show={showAddTaskModal}
          onClose={OnAddOrEditTask}
          type={taskModalType}
          data={taskModalData}
        />
      )) ||
        null}
      {(showUpdateStatusConfirmModal && (
        <AppDeleteConfirmationModal
          show={showUpdateStatusConfirmModal}
          onClose={() => setShowUpdateStatusModal(false)}
          onConfirm={handleUpdateTaskStatus}
          type={(filterOption && "deactivate") || "activate"}
        />
      )) ||
        null}
    </>
  );
};

export default Task;
