import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { upsertPage } from "../../../store/slices/page/pageSlice";
import CameraSOS from "./CameraSOS";
import CameraActive from "./CameraActive";
import { RootState } from "../../../store/store";
import AppButton from "../../../components/AppButton";
import { backIcon } from "../../../layout/Header";

const CameraListing: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const dispatch = useDispatch();
  const { title, backURL } = useSelector((state: RootState) => state?.page);
  const { allCameras } = useSelector((state: RootState) => state?.camera);
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "active") {
      dispatch(
        upsertPage({
          title: "In-Use Cameras",
          classes: "site-dashboard site-camera-listing",
        })
      );
    } else if (type === "sos") {
      dispatch(
        upsertPage({
          title: "SOS Alerts",
          classes: "site-dashboard site-camera-listing",
        })
      );
    }
  }, [type]);

  return (
    <>
      {(!!backURL && (
        <h1 className="mb-3 d-flex d-md-none align-items-center">
          <AppButton
            custom
            className="button--back"
            onClick={() => navigate(backURL)}
          >
            {backIcon}
          </AppButton>
          {title}
        </h1>
      )) ||
        null}
      {(type === "sos" && (
        <div className="cameras sos m-0">
          <div className="row">
            {allCameras
              .filter((camera) => camera.sos)
              .map((camera) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 my-2"
                  key={camera._id}
                >
                  <CameraSOS
                    streamName={camera.channel_name as string}
                    cleanerId={camera?.user?._id}
                    cleanerName={camera.user?.name || camera.user?.email}
                    cameraManufacturer={camera?.device_manufacturer}
                    cameraModel={camera?.device_model}
                    deviceType={camera?.device_type}
                  />
                </div>
              ))}
          </div>
        </div>
      )) ||
        (type === "active" && (
          <div className="cameras active m-0">
            <div className="row">
              {allCameras
                .filter((camera) => camera.status === "play" && !camera.sos)
                .map((camera) => (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3 my-2"
                    key={camera._id}
                  >
                    <CameraActive
                      streamName={camera.channel_name as string}
                      cleanerId={camera?.user?._id}
                      cleanerName={camera.user?.name || camera.user?.email}
                      cameraManufacturer={camera?.device_manufacturer}
                      cameraModel={camera?.device_model}
                      deviceType={camera?.device_type}
                    />
                  </div>
                ))}
            </div>
          </div>
        )) ||
        null}
    </>
  );
};

export default CameraListing;
