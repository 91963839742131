import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../../../components/AppInput";
import { emailRegex, passwordRegex } from "../../../../utils/Regex";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import { generatePassword } from "../../../../utils/GeneratePassword";
import AppCheckbox from "../../../../components/AppCheckbox";
import { useSelector, useDispatch } from "react-redux";
import { updateUserRegistrationFormState } from "../../../../store/slices/user/userRegistrationFormSlice";
import { RootState } from "../../../../store/store";
import AppPhoneInput from "../../../../components/AppPhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";

interface FormTypes {
  email: string;
  phone_no: string;
  password: string;
  first_login: boolean;
  reset_passowrd: boolean;
}

const PersonalDetails = forwardRef(({}, ref) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userFormState = useSelector(
    (state: RootState) => state?.userRegistrationForm
  );
  const [showPassword, setShowPassword] = useState(false);
  const [firstLogin, _setfirstLogin] = useState(true);
  const [passwordReset, setPasswordReset] = useState(false);
  let { userId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  useImperativeHandle(ref, () => ({
    triggerUserStep2FormValidation: async () => {
      if (userFormState?.is_step_2_complete) {
        const valid = await trigger();
        if (valid) {
          const updateUserRegistrationFormStateValue: any = {
            email: getValues("email"),
            password: getValues("password"),
            phone_no: getValues("phone_no"),
            first_login: getValues("first_login"),
          };
          dispatch(
            updateUserRegistrationFormState(
              updateUserRegistrationFormStateValue
            )
          );
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  }));

  useEffect(() => {
    userFormState?.email && setValue("email", userFormState?.email);
    userFormState?.password && setValue("password", userFormState?.password);
    userFormState?.phone_no && setValue("phone_no", userFormState?.phone_no);

    userFormState?.role?.value === "cleaner"
      ? (setShowPassword(false), setValue("password", ""))
      : setShowPassword(true);
    if (userId) {
      setShowPassword(false);
    }
  }, []);

  useEffect(() => {
    if (
      userFormState?.user_form_type === "add" &&
      userFormState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [userFormState?.user_form_type, userFormState?.is_all_state_reset]);

  const handleGeneratePassword = async () => {
    const password = generatePassword();
    setValue("password", password);
    await trigger("password");
  };

  const handleResetPassword = (value: any) => {
    setPasswordReset(value);
    if (value) {
      setShowPassword(true);
    } else {
      setShowPassword(false), setValue("password", "");
    }
  };

  const onSubmit: SubmitHandler<FormTypes> = (data) => {
    //console.log(data);
    // //navigate("/user-management/add?step=3");
    const updateUserRegistrationFormStateValue: any = {
      is_step_2_complete: true,
      email: data.email,
      password: data?.password,
      phone_no: data?.phone_no,
      first_login: data.first_login,
    };

    dispatch(
      updateUserRegistrationFormState(updateUserRegistrationFormStateValue)
    );

    if (userFormState?.user_form_type === "add") {
      navigate("/user-management/add?step=3");
    } else {
      navigate(`/user-management/edit/${userId}?step=3`);
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">Personal Details</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: emailRegex,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Email"
                value={value || ""}
                onChange={onChange}
                isRequired
              />
            )}
          />
          {errors?.email?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.email?.type === "pattern" && (
            <span className="form-status error">
              Please enter a valid email id
            </span>
          )}
        </div>
        {/* <div className="mb-4">
          <Controller
            name="phone_no"
            control={control}
            defaultValue=""
            rules={{
              required: false,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="phone_no"
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
        </div> */}
        <div className="m-0">
          <Controller
            control={control}
            name="phone_no"
            rules={{
              required: false,
              validate: (value: string) => {
                if (!!value) {
                  return isValidPhoneNumber(value);
                }
              },
            }}
            render={({ field: { onChange, value } }) => (
              <AppPhoneInput
                onChange={onChange}
                value={value}
                // defaultCountry={"IN"}
                label="Phone No"
              />
            )}
          />

          {errors?.phone_no?.type === "validate" && (
            <span className="form-status error">Enter a valid phone no.</span>
          )}
        </div>

        {userFormState?.user_form_type === "edit" &&
        userFormState?.role?.value !== "cleaner" ? (
          <div className="mt-4 pb-2">
            <Controller
              name="reset_passowrd"
              control={control}
              defaultValue={passwordReset}
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppCheckbox
                  checked={value}
                  value={value}
                  onChange={(e) => {
                    handleResetPassword(e.target.checked);
                    onChange(e);
                  }}
                  label="Reset password"
                />
              )}
            />
          </div>
        ) : (
          ""
        )}

        {showPassword ? (
          <div className="my-4">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: showPassword ? true : false,
                pattern: passwordRegex,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Password"
                  isRequired
                  isPassword
                  isGeneratePassword
                  onGeneratePassword={handleGeneratePassword}
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            {errors?.password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.password?.type === "pattern" && (
              <span className="form-status error">
                The password must contain at least 1 lowercase alphabetical
                character, 1 uppercase alphabetical character, 1 numeric
                character, 1 special character, between 8 - 16 character.
              </span>
            )}
          </div>
        ) : (
          ""
        )}

        {showPassword ? (
          <div className="m-0">
            <Controller
              name="first_login"
              control={control}
              defaultValue={firstLogin}
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppCheckbox
                  checked={value}
                  value={value}
                  onChange={onChange}
                  label="Reset password on next login"
                />
              )}
            />
          </div>
        ) : (
          ""
        )}

        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Next
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
});

export default PersonalDetails;
