import React, { useState } from "react";
import IconShowPassword from "@/images/icon__password--show.svg";
import IconHidePassword from "@/images/icon__password--hide.svg";
import IconGeneratePassword from "@/images/icon__generatePassword.svg";
interface AppInputTypes {
  className?: string;
  value: string | null | undefined;
  label: string;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isPassword?: boolean;
  isGeneratePassword?: boolean;
  onGeneratePassword?: () => void;
}

const AppInput: React.FC<AppInputTypes> = ({
  className,
  value,
  label,
  type = "text",
  disabled,
  readOnly,
  placeholder,
  onChange,
  isRequired = false,
  isPassword,
  isGeneratePassword,
  onGeneratePassword,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={`form-floating ${
        (!!value && isPassword && !isGeneratePassword && "action single") ||
        (!!value && isPassword && isGeneratePassword && "action double") ||
        ""
      }`}
    >
      <input
        className={`form-control ${(!!className && className) || ""}`}
        value={value || ""}
        type={(isPassword && !showPassword && "password") || "text"}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder || ""}
        onChange={onChange}
        {...props}
      />
      <label>
        {label} {(isRequired && <sup>*</sup>) || null}
      </label>

      {(isPassword && (
        <div className="form-actions">
          {(isPassword && !!value && (
            <span onClick={() => setShowPassword(!showPassword)}>
              <img
                className="img-w-16"
                src={(showPassword && IconHidePassword) || IconShowPassword}
                alt=""
              />
            </span>
          )) ||
            null}
          {(isGeneratePassword && (
            <span onClick={onGeneratePassword} className="ms-3">
              <img className="img-w-16" src={IconGeneratePassword} alt="" />
            </span>
          )) ||
            null}
        </div>
      )) ||
        null}
    </div>
  );
};

export default AppInput;
