import { Link } from "react-router-dom";
import Logo from "@/images/logo--icon.svg";
import Arrow from "@/images/icon__formnext.svg";

function LinkedExpired() {
  return (
    <div className="site-404">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4 d-flex flex-column align-items-center">
            <img src={Logo} alt="" />
            <h2 className="mb-3">Link Expired</h2>
            <p className="mb-4 pb-2">
              To reset your password, return to the login page and select
              "Forgot Password?" to send a new email.
            </p>
            <Link to="/">
              Go to Login Page <img src={Arrow} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkedExpired;
