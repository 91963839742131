//@ts-nocheck
export const REACT_APP_EXTERNAL_URL = import.meta.env.VITE_BACKEND_URL;

export const pusherKey = import.meta.env.VITE_PUSHER_KEY;

//@ts-nocheck
// export const REACT_APP_EXTERNAL_URL = import.meta.env.DEV
//   ? import.meta.env.VITE_BACKEND_URL
//   : "https://api.helpicams.com";

export const sortOptions = [
  {
    label: "Name ASC",
    value: "name_asc",
  },
  {
    label: "Name DESC",
    value: "name_desc",
  },
  {
    label: "Reg. Date ASC",
    value: "created_asc",
  },
  {
    label: "Reg. Date DESC",
    value: "created_desc",
  },
];

export const taskAssignmentSortOptions = [
  {
    label: "Task ASC",
    value: "task_asc",
  },
  {
    label: "Task DESC",
    value: "task_desc",
  },
  {
    label: "Assigned Date ASC",
    value: "assigned_asc",
  },
  {
    label: "Assigned Date DESC",
    value: "assigned_desc",
  },
];

export const cameraSortOptions = [
  {
    label: "Camera Name ASC",
    value: "name_asc",
  },
  {
    label: "Camera Name DESC",
    value: "name_desc",
  },
  {
    label: "Reg. Date ASC",
    value: "created_asc",
  },
  {
    label: "Reg. Date DESC",
    value: "created_desc",
  },
];

export const filterOptions = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

export const filterOptionsIsComplete = [
  {
    label: "Complete",
    value: "complete",
  },
  {
    label: "Incomplete",
    value: "incomplete",
  },
];

export const maxLengthTextarea = 500;

export const cameraManufacturers = [
  "gopro",
  "boblov",
  "kingtop",
  "toooair",
  "matrix",
  "blazevideo",
  "eyelog",
  "z2",
];
