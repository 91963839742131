import { createSlice } from "@reduxjs/toolkit";

export interface HotelState {
  _id: string | null;
  is_step_1_complete: boolean;
  is_step_2_complete: boolean;
  is_step_3_complete: boolean;
  hotel_form_type: string | null;
  hotel_name: string | null;
  hotel_address: string | null;
  hotel_primary_email: string | null;
  hotel_secondary_email: string | null;
  hotel_primary_phone_no: string | null;
  hotel_secondary_phone_no: string | null;
  hotel_area: string | null;
  is_all_state_reset: boolean;
}

const initialState: HotelState = {
  _id: null,
  is_step_1_complete: false,
  is_step_2_complete: false,
  is_step_3_complete: false,
  hotel_form_type: "add",
  hotel_name: null,
  hotel_address: null,
  hotel_primary_email: null,
  hotel_secondary_email: null,
  hotel_primary_phone_no: null,
  hotel_secondary_phone_no: null,
  hotel_area: null,
  is_all_state_reset: true,
};

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    updateHotelState: (state: any, action: any) => ({
      ...state,
      ...action.payload,
    }),
    hotelStateSubmitted: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase("LOGOUT", (state) => {
        Object.assign(state, initialState);
      })
      .addCase("RESET_FORM", (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { updateHotelState, hotelStateSubmitted } = hotelSlice.actions;
export default hotelSlice.reducer;
