import axios from "axios";
import { store } from "../store/store";
import API from "../Network/API";
import isRefreshTokenExpired from "../helper/authHepler";
import { signIn } from "../store/slices/user/userSlice";
import { REACT_APP_EXTERNAL_URL } from "../utils/constant";

export const baseURL = REACT_APP_EXTERNAL_URL;

const http = axios.create({
  baseURL: baseURL,
});

const Network = {
  http: http,
  get: async (url: string, params?: any) => {
    return http.get(url, { params: params });
  },
  post: async (url: string, data?: any, config: any = {}) => {
    return http.post(url, data, config);
  },
};

Network.http.interceptors.request.use(async (config) => {
  let userData = store.getState().user;
  // to restrict call to refreshToken during login
  if (userData.token) {
    if (userData.token && isRefreshTokenExpired(userData?.token as string)) {
      if (config.url !== API.refreshToken) {
        const response = await Network.http.post(API.refreshToken, {
          refresh_token: `Bearer ${userData?.refresh_token}`,
        });
        userData = {
          ...userData,
          token: response.data.token,
          refresh_token: response.data.refresh_token,
        };
        store.dispatch(signIn(userData as any));
        config.headers.Authorization = `Bearer ${userData.token}`;
      }
    } else {
      config.headers.Authorization = `Bearer ${userData?.token}`;
    }
    return config;
  } else {
    return config;
  }
});

export default Network;
