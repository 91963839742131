export const generatePassword = (): string => {
  const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";
  const number = "0123456789";
  const doubleQuote = `"`;
  const specialcharacter = "~`!@#$%^&*()-_=+{}[]|:;'<,>.?/" + doubleQuote;
  const charset = `${upperCase}${lowerCase}${number}${specialcharacter}`;

  let password = "";

  const array = [4, 5, 6, 7, 8, 9, 10, 11, 12];

  const randomIndex = Math.floor(Math.random() * array.length);
  const randomElement = array[randomIndex];

  for (let i = 0; i < randomElement; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  const upperCaseIndex = Math.floor(Math.random() * upperCase.length);
  const lowerCaseIndex = Math.floor(Math.random() * lowerCase.length);
  const specialCharacterIndex = Math.floor(
    Math.random() * specialcharacter.length
  );
  const numberIndex = Math.floor(Math.random() * number.length);

  password +=
    upperCase[upperCaseIndex] +
    lowerCase[lowerCaseIndex] +
    number[numberIndex] +
    specialcharacter[specialCharacterIndex];

  return password;
};
