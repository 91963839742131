import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

interface AppDateTimePickerType {
  value: any;
  onChange: (date: Date | null) => void;
  minDate?: any;
  maxDate?: any;
  disabled?: boolean;
  label: string;
  isRequired?: boolean;
  showTimeInput?: boolean;
}

const AppDateTimePicker: React.FC<AppDateTimePickerType> = ({
  value,
  onChange,
  minDate,
  maxDate,
  disabled,
  label,
  isRequired,
  showTimeInput = true,
}) => {
  const [focusClass, setFocusClass] = useState<string | undefined | null>("");

  useEffect(() => {
    if (!!value) {
      setFocusClass("focused");
    } else {
      setFocusClass("");
    }
  }, [value]);

  const handleFocus = () => {
    if (!value) {
      setFocusClass("focused");
    }
  };

  const handleBlur = () => {
    if (!value) {
      setFocusClass("");
    }
  };

  return (
    <div className={`form-datetime-picker ${focusClass}`}>
      <DatePicker
        selected={value ? moment(value).toDate() : null}
        onChange={(date) => {
          onChange(date);
        }}
        dateFormat={showTimeInput ? "MM/dd/yyyy h:mm aa" : "MM/dd/yyyy"}
        timeInputLabel="Time:"
        showTimeInput={showTimeInput}
        disabled={disabled}
        minDate={minDate ? moment(minDate).toDate() : undefined}
        maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        onFocus={() => handleFocus()}
        onBlur={() => handleBlur()}
        showMonthDropdown
        showYearDropdown
      />
      <label>
        {label} {(isRequired && <sup>*</sup>) || null}
      </label>
    </div>
  );
};

export default AppDateTimePicker;
