import React, { ReactNode, useContext, useEffect, useRef } from "react";
import Pusher from "pusher-js";
import { pusherKey } from "../utils/constant";

const PusherContext = React.createContext<Pusher | undefined>(undefined);
export const usePusher = () => useContext(PusherContext);

export const PusherProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const clientRef = useRef<Pusher>();

  useEffect(() => {
    if (!clientRef.current) {
      clientRef.current = new Pusher(pusherKey, {
        cluster: "ap4",
      });
    }
  }, [clientRef]);

  return (
    <PusherContext.Provider value={clientRef?.current}>
      {children}
    </PusherContext.Provider>
  );
};
