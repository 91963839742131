import { createSlice } from "@reduxjs/toolkit";

export interface CameraState {
  _id: string | null;
  name: string | null;
  camera_unique_id: string | null;
  channel_name: string | null;
  device_id: string | null;
  device_name: string | null;
  device_manufacturer: string | null;
  device_model: string | null;
  device_type: string | null;
  serial_no: string | null;
  arn: string | null;
  hotel: string | null;
  is_active: boolean;
  sos: boolean;
  status: string | null;
  user: any | null;
}

interface Camera {
  allCameras: CameraState[];
}

const initialState: Camera = {
  allCameras: [],
};

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    updateAllCamera: (_: Camera, action: any) => {
      return {
        allCameras: action.payload,
      };
    },
    updateCameraState: (state: any, action: any) => {
      const allCameras = [...state.allCameras];
      const index = allCameras.findIndex(
        (cam) => cam._id === action.payload._id
      );
      if (index !== -1) {
        allCameras[index] = action.payload;
        return {
          allCameras,
        };
      } else {
        return {
          allCameras: [action.payload],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("LOGOUT", (state) => {
        Object.assign(state, initialState);
      })
      .addCase("RESET_FORM", (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const { updateAllCamera, updateCameraState } = cameraSlice.actions;
export default cameraSlice.reducer;
