import React from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import API from "../../Network/API";
import Network from "../../Network/Network";
import { passwordRegex } from "../../utils/Regex";

interface ModalTypes {
  show: boolean;
  onClose: any;
  data: any;
  type: any;
}

interface FormTypes {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
}

const FirstLoginChangePasswordModal: React.FC<ModalTypes> = ({
  show,
  onClose,
  data,
  type,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<FormTypes> = async (formData) => {
    let final_data = {};
    if (type === "first_login") {
      final_data = { ...formData, userId: data.id, first_login: false };
    } else {
      final_data = { ...formData, userId: data.id };
    }
    try {
      const response: any = await Network.post(API.changePassword, final_data);

      onClose(response);
      toast.success("Password updated");
    } catch (e: any) {
      console.error(e);
      toast.error(e.response.data.message);
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <Controller
              name="current_password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Current Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

            {errors?.current_password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="new_password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: passwordRegex,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="New Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

            {errors?.new_password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.new_password?.type === "pattern" && (
              <span className="form-status error">
                The password must contain at least 1 lowercase alphabetical
                character, 1 uppercase alphabetical character, 1 numeric
                character, 1 special character, between 8 - 16 character.
              </span>
            )}
          </div>
          <div className="mb-4 pb-md-2">
            <Controller
              name="confirm_new_password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: (value: any) => {
                  if (value !== watch("new_password")) {
                    return "Password and confirm password must be same";
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Confirm New Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />

            {errors?.confirm_new_password?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}

            {errors?.confirm_new_password?.type === "validate" && (
              <span className="form-status error">
                {errors?.confirm_new_password?.message}
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppButton type="submit" className="primary">
            Update
          </AppButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default FirstLoginChangePasswordModal;
