import { useEffect, forwardRef, useImperativeHandle } from "react";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../../../components/AppInput";
import AppTextarea from "../../../../components/AppTextarea";
import { useSelector, useDispatch } from "react-redux";
import { updateHotelState } from "../../../../store/slices/hotel/hotelSlice";
import { RootState } from "../../../../store/store";

interface FormTypes {
  hotel_name: string;
  address: string;
}

const PlaceInfo = forwardRef(({}, ref) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelState = useSelector((state: RootState) => state?.hotel);
  let { hotelId } = useParams();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  useImperativeHandle(ref, () => ({
    triggerHotelStep1FormValidation: async () => {
      if (hotelState?.is_step_1_complete) {
        const valid = await trigger();
        if (valid) {
          const updateHotelStateValue: any = {
            hotel_name: getValues("hotel_name"),
            hotel_address: getValues("address"),
          };
          dispatch(updateHotelState(updateHotelStateValue));
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  }));

  useEffect(() => {
    hotelState?.hotel_name && setValue("hotel_name", hotelState?.hotel_name);
    hotelState?.hotel_address && setValue("address", hotelState?.hotel_address);
  }, []);

  useEffect(() => {
    if (
      hotelState?.hotel_form_type === "add" &&
      hotelState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [hotelState?.hotel_form_type, hotelState?.is_all_state_reset]);

  const onSubmit: SubmitHandler<FormTypes> = () => {
    // console.log(data);
    const current_form_state = hotelState?.hotel_form_type;
    const updateHotelStateValue: any = {
      is_step_1_complete: true,
      hotel_name: getValues("hotel_name"),
      hotel_address: getValues("address"),
      is_all_state_reset: false,
    };
    dispatch(updateHotelState(updateHotelStateValue));

    if (current_form_state === "add") {
      navigate("/hotel-management/add?step=2");
    } else {
      navigate(`/hotel-management/edit/${hotelId}?step=2`);
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">What is your building name & address?</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="hotel_name"
            control={control}
            defaultValue={""}
            rules={{
              required: true,
              maxLength: 60,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="Building Name"
                isRequired
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.hotel_name?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.hotel_name?.type === "maxLength" && (
            <span className="form-status error">
              Building Name can be of maximum 60 characters
            </span>
          )}
        </div>
        <div className="mb-0">
          <Controller
            name="address"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 200,
            }}
            render={({ field: { value, onChange } }) => (
              <AppTextarea
                label="Address"
                isRequired
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.address?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.address?.type === "maxLength" && (
            <span className="form-status error">
              Maximum of 200 characters allowed
            </span>
          )}
        </div>
        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Next
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
});

export default PlaceInfo;
