import React, { useEffect, useState } from "react";
import Select from "react-select";

interface AppSelectType {
  defaultValue?: { label: string; value: string };
  value:
    | { label: string; value: string }
    | { label: string; value: string }[]
    | any;
  className?: string;
  options: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  onChange: (e: any) => void;
  menuIsOpen?: boolean;
  label: string;
  isRequired?: boolean;
  itemMissingText?: string;
}

const AppSelect: React.FC<AppSelectType> = ({
  className,
  options,
  defaultValue,
  value,
  onChange,
  isClearable = true,
  isSearchable = false,
  isMulti = false,
  isDisabled = false,
  menuIsOpen,
  label,
  isRequired,
  itemMissingText = "",
}) => {
  const [focusClass, setFocusClass] = useState<string | undefined | null>("");

  useEffect(() => {
    if (isMulti) {
      if (!!value && !!value?.length) {
        setFocusClass("focused");
      } else {
        setFocusClass("");
      }
    } else {
      if (!!value) {
        setFocusClass("focused");
      } else {
        setFocusClass("");
      }
    }
  }, [isMulti, value]);

  return (
    <div
      className={`form-selectbox ${
        (!!className && className) || ""
      } ${focusClass}`}
    >
      <Select
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={null}
        isClearable={isClearable}
        isSearchable={isSearchable}
        isMulti={isMulti || false}
        isDisabled={isDisabled}
        noOptionsMessage={() => itemMissingText || "No Items Found"}
        menuIsOpen={menuIsOpen}
        onFocus={() => setFocusClass("focused")}
        onBlur={() => !value && setFocusClass("")}
      />
      <label>
        {label} {(isRequired && <sup>*</sup>) || null}
      </label>
    </div>
  );
};

export default AppSelect;
