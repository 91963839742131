import { createSlice } from "@reduxjs/toolkit";

export interface userRegistrationFormState {
  _id: string | null;
  is_step_1_complete: boolean;
  is_step_2_complete: boolean;
  is_step_3_complete: boolean;
  user_form_type: string | null;
  name: string | null;
  email: string | null;
  password: string | null;
  phone_no: string | null;
  employee_id: string | null;
  image: string | null;
  role: { label: string; value: string } | null;
  first_login: boolean;
  hotels: [{ label: string; value: string }] | null;
  resetOnLogin: boolean;
  is_all_state_reset: boolean;
}

const initialState: userRegistrationFormState = {
  _id: null,
  is_step_1_complete: false,
  is_step_2_complete: false,
  is_step_3_complete: false,
  user_form_type: "add",
  name: null,
  email: null,
  password: null,
  phone_no: null,
  employee_id: null,
  image: null,
  role: null,
  first_login: true,
  hotels: null,
  resetOnLogin: true,
  is_all_state_reset: true,
};

export const userRegistrationFormSlice = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    updateUserRegistrationFormState: (state: any, action: any) => ({
      ...state,
      ...action.payload,
    }),
    userRegistrationFormStateSubmitted: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase("LOGOUT", (state) => {
        Object.assign(state, initialState);
      })
      .addCase("RESET_FORM", (state) => {
        Object.assign(state, initialState);
      });
  },
});

export const {
  updateUserRegistrationFormState,
  userRegistrationFormStateSubmitted,
} = userRegistrationFormSlice.actions;
export default userRegistrationFormSlice.reducer;
