import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Network from "../../../../Network/Network";
import API from "../../../../Network/API";

const RegisteredCameraDetails: FC = () => {
  const [camera, setCamera] = useState<any | null>(null);

  const params = useParams();
  const { cameraId } = params;

  const fetchSpecificCameraDetails = () => {
    try {
      Network.get(API.fetchSpecificCameraDetails, { cameraId })
        .then((resp: any) => {
          setCamera(resp.data.cameraDetails || null);
        })
        .catch(console.error);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSpecificCameraDetails();
  }, [cameraId]);

  return (
    <div className="row h-md-100">
      <div className="col-md-12 d-flex align-items-center">
        <div className="row flex-grow-1 justify-content-center">
          <div className="col-md-6">
            <div className="d-flex justify-content-center mb-4 mb-md-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="154"
                height="154"
                viewBox="0 0 154 154"
                fill="none"
              >
                <path
                  d="M30 2H22C10.9543 2 2 10.9543 2 22V30"
                  stroke="#2E5A76"
                  strokeWidth="3"
                  className="lens__outer--tl"
                />
                <path
                  d="M152 30L152 22C152 10.9543 143.046 2 132 2L124 2"
                  stroke="#2E5A76"
                  strokeWidth="3"
                  className="lens__outer--tr"
                />
                <path
                  d="M124 152L132 152C143.046 152 152 143.046 152 132L152 124"
                  stroke="#2E5A76"
                  strokeWidth="3"
                  className="lens__outer--br"
                />
                <path
                  d="M2 124L2 132C2 143.046 10.9543 152 22 152L30 152"
                  stroke="#2E5A76"
                  strokeWidth="3"
                  className="lens__outer--bl"
                />
                <g className="lens__inner">
                  <circle
                    cx="76.7551"
                    cy="76.7551"
                    r="62.7551"
                    fill="#D2E6F5"
                  />
                  <circle
                    cx="76.7552"
                    cy="76.7551"
                    r="50.2041"
                    fill="#2E5A76"
                  />
                  <circle
                    cx="76.7551"
                    cy="76.7552"
                    r="37.6531"
                    fill="#284D65"
                  />
                  <circle cx="76.7551" cy="76.7551" r="25.102" fill="#22313A" />
                  <circle cx="63.7857" cy="67.9694" r="11.2959" fill="white" />
                  <circle
                    cx="79.2652"
                    cy="83.4489"
                    r="4.18367"
                    transform="rotate(180 79.2652 83.4489)"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
            <h3 className="text-center mb-5 pb-md-5">
              Registered Camera Details
            </h3>
          </div>
          <div className="col-md-12">
            <div className="row weight-500">
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block color-light">Camera Name</span>
                {camera?.name || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block color-light">Hotel Name</span>
                {camera?.hotel?.name || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block color-light">Serial No.</span>
                {camera?.serial_no || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block color-light">Unique ID</span>
                {camera?.camera_unique_id || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                <span className="d-block color-light">Channel Name:</span>
                {camera?.channel_name || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                <span className="d-block color-light">Device Id:</span>
                {camera?.device_id || "-"}
              </div>
              <div className="col-md-6 col-xl-3 mb-4 mb-md-0">
                <span className="d-block color-light">Device Name:</span>
                {camera?.device_name || "-"}
              </div>
              <div className="col-md-6 col-xl-3">
                <span className="d-block color-light">ARN:</span>
                {camera?.arn || "-"}
              </div>
            </div>
            <div className="d-flex mt-4 mt-md-5">
              <Link
                className="color-primary underlined mx-auto weight-500"
                to="/camera-management/registered-cameras"
              >
                Go to Camera Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredCameraDetails;
