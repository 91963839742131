import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  id: string | null;
  name: string | null;
  email: string | null;
  phone_no: string | null;
  account_no: string | null;
  employee_id: string | null;
  image: string | null;
  role: string | null;
  is_active: boolean;
  first_login: boolean;
  refresh_token: string | null;
  token: string | null;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  id: null,
  name: null,
  email: null,
  phone_no: null,
  account_no: null,
  employee_id: null,
  image: null,
  role: null,
  is_active: false,
  first_login: false,
  refresh_token: null,
  token: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signIn: (state: any, action: any) => {
      // ...state,
      // ...action.payload,
      // isLoggedIn: true,
      Object.keys(action.payload).forEach((key) => {
        switch (key) {
          case "id":
            state.id = action.payload.id;
            break;
          case "name":
            state.name = action.payload.name;
            break;
          case "email":
            state.email = action.payload.email;
            break;
          case "phone_no":
            state.phone_no = action.payload.phone_no;
            break;
          case "account_no":
            state.account_no = action.payload.account_no;
            break;
          case "employee_id":
            state.employee_id = action.payload.employee_id;
            break;
          case "image":
            state.image = action.payload.image;
            break;
          case "role":
            state.role = action.payload.role;
            break;
          case "is_active":
            state.is_active = action.payload.is_active;
            break;
          case "first_login":
            state.first_login = action.payload.first_login;
            break;
          case "refresh_token":
            state.refresh_token = action.payload.refresh_token;
            break;
          case "token":
            state.token = action.payload.token;
            break;
          case "isLoggedIn":
            state.isLoggedIn = action.payload.isLoggedIn;
            break;
        }
      });
    },
    signOut: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const { signIn, signOut } = userSlice.actions;
export default userSlice.reducer;
