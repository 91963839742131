import { FC, useEffect, useState } from "react";
import moment from "moment";
import IconCalendar from "@/images/icon__calendar.svg";
import IconUser from "@/images/icon__user.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppButton from "../components/AppButton";
import Logo from "@/images/logo--icon.svg";
import { upsertPage } from "../store/slices/page/pageSlice";

export const backIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7071 14.7071C11.3165 15.0976 10.6834 15.0976 10.2929 14.7071L4.29287 8.70662C3.90236 8.31608 3.90238 7.68291 4.29292 7.2924L10.2929 1.29287C10.6835 0.902357 11.3166 0.902381 11.7071 1.29292C12.0976 1.68346 12.0976 2.31662 11.7071 2.70713L6.41421 7.99959L11.7071 13.2929C12.0976 13.6835 12.0976 14.3166 11.7071 14.7071Z"
    />
  </svg>
);

const Header: FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);
  const userDetails = useSelector((store: any) => store.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const dispatch = useDispatch();

  const [link, setLink] = useState<string>();
  const [showNavigation, setShowNavigation] = useState(false);

  useEffect(() => {
    pathname === "/hotel-management/add"
      ? setLink("/hotel-management")
      : pathname.includes("/hotel-management/edit")
      ? setLink("/hotel-management")
      : pathname === "/user-management/add"
      ? setLink("/user-management")
      : pathname.includes("/user-management/edit")
      ? setLink("/user-management")
      : pathname === "/camera-management/lists" && type === "sos"
      ? setLink("/camera-management")
      : pathname === "/camera-management/lists" && type === "active"
      ? setLink("/camera-management")
      : pathname === "/camera-management/registered-cameras"
      ? setLink("/camera-management")
      : pathname === "/camera-management/details"
      ? setLink("/camera-management")
      : pathname === "/camera-management/add"
      ? setLink("/camera-management/registered-cameras")
      : setLink("");

    if (showNavigation) {
      setShowNavigation(false);
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(upsertPage({ showNavigation, backURL: link }));
  }, [showNavigation, link]);

  return (
    <div className="site-header">
      <div className="logo">
        <img src={Logo} alt="" />
      </div>
      {(!!title && !!link && (
        <h1 className="m-0 d-none d-md-flex align-items-center">
          <AppButton
            custom
            className="button--back"
            onClick={() => navigate(link)}
          >
            {backIcon}
          </AppButton>
          {title !== "Registered Cameras" && title}
        </h1>
      )) ||
        null}
      <span className="bg-background--light weight-500 d-none d-xl-flex">
        <img src={IconCalendar} alt="" className="me-2" />
        {moment().format("LL")}
      </span>
      <span className="bg-background--light weight-500">
        <img
          src={IconUser}
          alt=""
          className={(userDetails?.name && "me-2") || ""}
        />
        {userDetails?.name}
      </span>
      <AppButton
        custom
        className={`toggle ${(showNavigation && "active") || ""}`}
        onClick={() => setShowNavigation(!showNavigation)}
      >
        <svg viewBox="0 0 100 100">
          <path
            className="line top"
            d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
          />
          <path className="line middle" d="m 70,50 h -40" />
          <path
            className="line bottom"
            d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
          />
        </svg>
      </AppButton>
    </div>
  );
};

export default Header;
