import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import AppButton from "../../../../components/AppButton";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppTextarea from "../../../../components/AppTextarea";
import AppSelect from "../../../../components/AppSelect";

import API from "../../../../Network/API";
import Network from "../../../../Network/Network";

interface ModalTypes {
  show: boolean;
  onClose: any;
  type: string;
  data: any;
}

type SelectType = { label: string; value: string };

interface FormTypes {
  hotel_name: SelectType | undefined | string;
  area_name: SelectType | undefined | string;
  task: string;
  phone_no: string;
}

const AddTaskModal: React.FC<ModalTypes> = ({ show, onClose, type, data }) => {
  const [allProcessedHotels, setAllProcessedHotels] = useState([]);
  const [allHotels, setAllHotels] = useState([]);
  const [allHotelArea, setHotelArea] = useState([]);
  const [taskEditId, setTaskEditId] = useState(null);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  const getAllhotels = async () => {
    try {
      const hotels = await Network.get(API.hotelList, {});
      const hotelData = hotels.data.hotels.map((keys: any) => ({
        label: keys.name,
        value: keys._id,
      }));

      if (type === "Edit") {
        const hotelData = hotels.data.hotels?.filter(
          //@ts-ignore
          (hotel) => hotel?._id == data?.hotel._id
        );
        //@ts-ignore
        const hotelAreaData = hotelData[0]?.areas?.map((keys: any) => ({
          label: keys.area,
          value: keys._id,
        }));

        setHotelArea(hotelAreaData);
      }

      setAllHotels(hotels.data.hotels);
      setAllProcessedHotels(hotelData);
    } catch (error) {
      toast.error("error occured");
    }
  };

  useEffect(() => {
    getAllhotels();
  }, []);

  useEffect(() => {
    if (type === "Edit" && !!data) {
      setTaskEditId(data?._id);
      setValue("hotel_name", {
        label: data?.hotel.name,
        value: data?.hotel._id,
      });
      setValue("area_name", {
        label: data?.area.area,
        value: data?.area._id,
      });
      setValue("task", data?.task);
    }
  }, [type, data]);

  useEffect(() => {
    if (watch("hotel_name")) {
      const hotelData = allHotels?.filter(
        //@ts-ignore
        (hotel) => hotel?._id === watch("hotel_name")?.value
      );
      //@ts-ignore
      const hotelAreaData = hotelData[0]?.areas?.map((keys: any) => ({
        label: keys.area,
        value: keys._id,
      }));

      setHotelArea(hotelAreaData);
    } else {
      setHotelArea([]);
      setValue("area_name", "");
    }
  }, [watch("hotel_name")]);

  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    const final_data = {
      _id: taskEditId ? taskEditId : null,
      //@ts-ignore
      hotel: data?.hotel_name?.value,
      //@ts-ignore
      area: data?.area_name?.value,
      task: data?.task,
    };

    try {
      const response: any = await Network.post(
        API.createUpdateTask,
        final_data
      );

      onClose(response?.status);
      if (taskEditId) {
        toast.success("Task details updated");
      } else {
        toast.success("Task details added");
      }
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong");
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{type} Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <Controller
              name="hotel_name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Building"
                  options={allProcessedHotels}
                  value={value || ""}
                  onChange={onChange}
                  isRequired
                />
              )}
            />
            {errors?.hotel_name?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="area_name"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Area"
                  options={allHotelArea}
                  value={value || ""}
                  onChange={onChange}
                  isRequired
                />
              )}
            />
            {errors?.area_name?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="m-0">
            <Controller
              name="task"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                maxLength: 500,
              }}
              render={({ field: { value, onChange } }) => (
                <AppTextarea
                  label="Task"
                  value={value || ""}
                  onChange={onChange}
                  isRequired
                />
              )}
            />
            {errors?.task?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.task?.type === "maxLength" && (
              <span className="form-status error">
                Maximum of 500 characters allowed
              </span>
            )}
          </div>
          {/* <div className="m-0">
            <Controller
              control={control}
              name="phone_no"
              rules={{
                required: true,
                validate: (value: string) => {
                  return isValidPhoneNumber(value);
                },
              }}
              render={({ field: { onChange, value } }) => (
                <AppPhoneInput
                  onChange={onChange}
                  value={value}
                  defaultCountry={"IN"}
                  label="Phone No"
                  isRequired
                />
              )}
            />
            {errors?.phone_no?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.phone_no?.type === "validate" && (
              <span className="form-status error">Enter a valid phone no.</span>
            )}
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <AppButton type="submit" className="primary">
            Save
          </AppButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddTaskModal;
