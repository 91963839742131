import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { upsertPage } from "./store/slices/page/pageSlice";
import { useEffect } from "react";
import { AppDispatch, RootState } from "./store/store";
import { ToastContainer } from "react-toastify";
import { signOut } from "./store/slices/user/userSlice";
import AppLoader from "./shared/AppLoader";
import { PusherProvider } from "./components/PusherProvider";

function App() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { classes, title } = useSelector((state: RootState) => state?.page);
  const { isLoggedIn, role, id } = useSelector(
    (state: RootState) => state?.user
  );

  const pageInfo = () => {
    pathname === "/signin"
      ? dispatch(
          upsertPage({ title: "Sign In", classes: "site-authentication" })
        )
      : pathname === "/forgot-password"
      ? dispatch(
          upsertPage({
            title: "Forgot Password",
            classes: "site-authentication",
          })
        )
      : pathname.includes("/reset-password")
      ? dispatch(
          upsertPage({
            title: "Reset Password",
            classes: "site-authentication",
          })
        )
      : pathname === "/camera-management"
      ? dispatch(
          upsertPage({
            title: "Camera Management",
            classes: "site-dashboard",
          })
        )
      : pathname === "/camera-management/registered-cameras"
      ? dispatch(
          upsertPage({
            title: "Registered Cameras",
            classes: "site-dashboard",
          })
        )
      : pathname === "/camera-management/details"
      ? dispatch(
          upsertPage({
            title: "Support",
            classes: "site-dashboard",
          })
        )
      : pathname === "/camera-management/add"
      ? dispatch(
          upsertPage({
            title: "Camera Registration",
            classes: "site-dashboard",
          })
        )
      : pathname.includes("/camera-management/view")
      ? dispatch(
          upsertPage({
            title: "Camera Registration",
            classes: "site-dashboard",
          })
        )
      : pathname === "/user-management"
      ? dispatch(
          upsertPage({
            title: "User Management",
            classes: "site-dashboard",
          })
        )
      : pathname.includes("/user-management/add")
      ? dispatch(
          upsertPage({
            title: "User Registration",
            classes: "site-dashboard site-registration",
          })
        )
      : pathname.includes("/user-management/edit")
      ? dispatch(
          upsertPage({
            title: "User Modification",
            classes: "site-dashboard site-registration",
          })
        )
      : pathname === "/hotel-management"
      ? dispatch(
          upsertPage({
            title: "Building Management",
            classes: "site-dashboard",
          })
        )
      : pathname.includes("/hotel-management/add")
      ? dispatch(
          upsertPage({
            title: "Building Registration",
            classes: "site-dashboard site-registration",
          })
        )
      : pathname.includes("/hotel-management/edit")
      ? dispatch(
          upsertPage({
            title: "Building Modification",
            classes: "site-dashboard site-registration",
          })
        )
      : pathname.includes("/hotel-management/edit")
      ? dispatch(
          upsertPage({
            title: "Building Modification",
            classes: "site-dashboard site-registration",
          })
        )
      : pathname === "/task"
      ? dispatch(
          upsertPage({
            title: "Tasks",
            classes: "site-dashboard",
          })
        )
      : pathname === "/task-assignment"
      ? dispatch(
          upsertPage({
            title: "Task Assignment",
            classes: "site-dashboard",
          })
        )
      : pathname === "/getting-started"
      ? dispatch(
          upsertPage({
            title: "How to get started",
            classes: "site-dashboard",
          })
        )
      : null;
  };

  useEffect(() => {
    if (pathname && pathname !== "/") pageInfo();
    if (
      (isLoggedIn && pathname === "/") ||
      (isLoggedIn && pathname === "/signin")
    ) {
      navigate("/camera-management");
    }
  }, [pathname]);

  useEffect(() => {
    document.title = title ? `${title} || Helpi` : `Helpi`;
  }, [title]);

  useEffect(() => {
    if (!id) {
      dispatch(signOut());
    } else {
      // console.log({ pathname, state, key, search });
      // navigate(pathname + search);
      if (pathname.includes("/sos-incident")) return;

      if (role === "admin") {
        if (
          pathname &&
          (pathname.includes("hotel-management") ||
            pathname.includes("user-management") ||
            pathname.includes("camera-management") ||
            pathname.includes("task"))
        ) {
          navigate(pathname + search);
        } else {
          navigate("/hotel-management");
        }
      } else if (role === "owner") {
        if (
          pathname &&
          (pathname.includes("hotel-management") ||
            pathname.includes("user-management") ||
            pathname.includes("camera-management"))
        ) {
          navigate(pathname + search);
        } else {
          navigate("/user-management");
        }
      } else if (role === "supervisor") {
        if (
          pathname &&
          (pathname.includes("task") ||
            pathname.includes("user-management") ||
            pathname.includes("camera-management"))
        ) {
          navigate(pathname + search);
        } else {
          navigate("/camera-management");
        }
      } else {
        dispatch(signOut());
      }

      // role === "admin"
      //   ? navigate("/hotel-management")
      //   : role === "owner"
      //   ? navigate("/user-management")
      //   : role === "supervisor"
      //   ? navigate("/camera-management")
      //   : dispatch(signOut());
    }
  }, [role, id, isLoggedIn]);

  return (
    <>
      <div className={`site ${classes}`}>
        <PusherProvider>
          <Outlet />
        </PusherProvider>
      </div>
      <ToastContainer />
      <AppLoader />
    </>
  );
}

export default App;
