import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import AppSearch from "../../../components/AppSearch";
import AppButton from "../../../components/AppButton";
import moment from "moment";
import AppTable from "../../../components/AppTable";
import AppTableAction from "../../../components/AppTableAction";
import { AppTableExpandRowSettings } from "../../../utils/AppTableExpandSettings";
import { Dropdown } from "react-bootstrap";
import IconFilter from "@/images/icon__filter.svg";
import { useNavigate } from "react-router-dom";
import {
  updateHotelState,
  //hotelStateSubmitted,
} from "../../../store/slices/hotel/hotelSlice";

import API from "../../../Network/API";
import Network from "../../../Network/Network";
import { sortOptions, filterOptions } from "../../../utils/constant";
import AppDeleteConfirmationModal from "../../../shared/AppDeleteConfirmationModal";
import IconClearFilter from "@/images/icon__clear-filter.svg";

import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";

const HotelManagement: React.FC = ({}) => {
  const { title } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [allhotels, setAllHotels] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setFilterOption] = useState(true);
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [showUpdateStatusConfirmModal, setShowUpdateStatusModal] =
    useState(false);
  const [toBeUpdatedBuildingStatus, setToBeUpdatedBuildingStatus] = useState({
    hotelId: "",
    status: "",
  });

  const perPage = 10;

  const onChangeSearchTerm = (termSearched: any) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const getAllhotels = async () => {
    dispatch(startLoading());
    try {
      const hotels = await Network.get(API.hotelList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortOption,
        active: filterOption,
      });
      setAllHotels(hotels.data.hotels);
      setTotalCount(hotels.data.total_count);
      dispatch(endLoading());
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    // dispatch(hotelStateSubmitted());
    dispatch({ type: "RESET_FORM" });
    getAllhotels();
  }, [page, searchTerm, sortOption, filterOption]);

  const editHotel = async (hotel: any) => {
    dispatch(startLoading());
    try {
      const response = await Network.get(
        `${API.fetchHotelDataById}?id=${hotel._id}`
      );

      const hotelDetails = response?.data?.hotel;

      const area_data = hotelDetails.areas.map((keys: any) => ({
        ...keys,
        name: keys.area,
      }));

      const updateHotelStateValue: any = {
        _id: hotel._id,
        is_step_1_complete: true,
        is_step_2_complete: true,
        is_step_3_complete: true,
        hotel_form_type: "edit",
        hotel_name: hotelDetails?.name,
        hotel_address: hotelDetails?.address,
        hotel_primary_email: hotelDetails?.email_primary,
        hotel_secondary_email: hotelDetails?.email_secondary,
        hotel_primary_phone_no: hotelDetails?.phone_no_primary,
        hotel_secondary_phone_no: hotelDetails?.phone_no_secondary,
        hotel_area: area_data,
      };

      dispatch(updateHotelState(updateHotelStateValue));
      dispatch(endLoading());
      navigate(`/hotel-management/edit/${hotel?._id}?step=1`);
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  const columns = [
    {
      dataField: "name",
      text: "Building Name",
      headerAttrs: { title: "Sort By:" },
      attrs: { title: "Building Name" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "date",
      text: "Reg. Date",
      attrs: { title: "Reg. Date" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, allhotels: any) => (
        <>{moment(allhotels?.createdAt).format("MMMM DD, YYYY")}</>
      ),
    },
    {
      dataField: "user",
      text: "Owner(s)",
      attrs: { title: "Owner(s)" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, allhotels: any) => (
        <>
          {(allhotels?.user && !!allhotels?.user?.length && (
            <div className="d-block">
              {allhotels?.user?.map((owner: any, idx: number) => (
                <Fragment key={idx}>
                  {(idx !== 0 && ", ") || null}
                  <span>{owner?.owner_hotel?.name}</span>
                </Fragment>
              ))}
            </div>
          )) ||
            "-"}
        </>
      ),
    },
    {
      dataField: "address",
      text: "Address",
      attrs: { title: "Address" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "action",
      text: "Action",
      attrs: { title: "Action" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => {
        return (
          <>
            {row.is_active ? (
              <AppTableAction
                row={row}
                onEdit={() => editHotel(row)}
                onUpdateStatus={() => {
                  setToBeUpdatedBuildingStatus({
                    hotelId: row._id,
                    status: "active",
                  });
                  setShowUpdateStatusModal(true);
                }}
                onUpdateStatusVisibility={row._id}
              />
            ) : (
              <AppTableAction
                row={row}
                onUpdateStatus={() => {
                  setToBeUpdatedBuildingStatus({
                    hotelId: row._id,
                    status: "inactive",
                  });
                  setShowUpdateStatusModal(true);
                }}
                onUpdateStatusVisibility={row._id}
              />
            )}
          </>
        );
      },
      headerStyle: {
        width: "120px",
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const expandRow = {
    ...AppTableExpandRowSettings,
    renderer: (allhotels: any) => {
      return (
        <>
          {(allhotels?.areas &&
            !!allhotels?.areas?.length &&
            allhotels?.areas?.map((i: any, idx: number) => (
              <div className={`row ${(idx !== 0 && "mt-3") || ""}`} key={idx}>
                <div className="col-md-6 mb-3 mb-md-0">
                  <h6 className="color-md-light mb-1">Area Name:</h6>
                  {i?.area.substring(0, 60) || "-"}
                </div>
                <div className="col-md-6">
                  <h6 className="color-md-light mb-1">Description:</h6>
                  {i?.description.substring(0, 60) || "-"}
                </div>
              </div>
            ))) ||
            "Area(s) are not defined for this building!"}
        </>
      );
    },
  };

  const handleUpdateBuildingStatus = async () => {
    setShowUpdateStatusModal(false);
    dispatch(startLoading());
    try {
      const response: any = await Network.get(
        `${
          toBeUpdatedBuildingStatus?.status === "active"
            ? API.deactivateHotel
            : API.activateHotel
        }`,
        { hotelId: toBeUpdatedBuildingStatus?.hotelId }
      );
      if (response !== undefined && response?.status === 200) {
        toast.success(response?.data?.message);

        setToBeUpdatedBuildingStatus({ hotelId: "", status: "" });
        getAllhotels();
        dispatch(endLoading());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      dispatch(endLoading());
    }
  };

  const handleResetFilter = () => {
    setShowResetIcon(false);
    setSortOption("");
    setFilterOption(true);
  };

  return (
    <>
      <h1>{title}</h1>
      <div className="d-md-flex align-items-center mb-4">
        <AppSearch value={searchTerm} onChangeSearchTerm={onChangeSearchTerm} />
        <div className="ms-auto d-flex align-items-center mt-3 mt-md-0 me-md-3">
          <Dropdown align={"end"} className="w-50 w-md-auto">
            <Dropdown.Toggle className="w-100">
              {sortOptions.find((option) => option.value === sortOption)
                ?.label || "Sort"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {sortOptions.map((option) => (
                <Dropdown.Item
                  key={option.value}
                  as={"button"}
                  onClick={() => {
                    setSortOption(option.value);
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={"end"} className="ms-3 w-50 w-md-auto">
            <Dropdown.Toggle className="has-icon w-100">
              {/* Filter <img src={IconFilter} alt="" /> */}
              {filterOptions.find((option) => option.value === filterOption)
                ?.label || "Filter"}{" "}
              <img src={IconFilter} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filterOptions.map((option) => (
                <Dropdown.Item
                  key={option?.label}
                  as={"button"}
                  onClick={() => {
                    setPage(1), setFilterOption(option.value);
                    if (option.value === true) return;
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mt-3 mt-md-0">
          {(showResetIcon && (
            <AppButton className="danger me-3" onClick={handleResetFilter}>
              <img className="img-h-20" src={IconClearFilter} alt="" />
            </AppButton>
          )) ||
            null}
          <AppButton
            className="primary w-100 w-md-auto"
            onClick={() => navigate("/hotel-management/add?step=1")}
          >
            + Register Building
          </AppButton>
        </div>
      </div>
      <AppTable
        classes="no-filter"
        headerClasses="empty"
        keyField="_id"
        columns={columns}
        rows={allhotels || []}
        expandRow={expandRow}
        pagination
        totalCount={totalCount}
        perPage={perPage}
        page={page}
        onPageChange={setPage}
      />
      {(showUpdateStatusConfirmModal && (
        <AppDeleteConfirmationModal
          show={showUpdateStatusConfirmModal}
          onClose={() => setShowUpdateStatusModal(false)}
          onConfirm={handleUpdateBuildingStatus}
          type={(filterOption && "deactivate") || "activate"}
        />
      )) ||
        null}
    </>
  );
};

export default HotelManagement;
