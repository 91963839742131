import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../../layout/Navigation";
import Header from "../../layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  updateAllCamera,
  updateCameraState,
} from "../../store/slices/camera/cameraSlice";

import { usePusher } from "../../components/PusherProvider";
import { toast } from "react-toastify";

const Dashboard: React.FC = () => {
  const { showNavigation } = useSelector((state: RootState) => state?.page);
  const { allCameras } = useSelector((state: RootState) => state?.camera);
  const dispatch = useDispatch();
  const pusher = usePusher();

  useEffect(() => {
    if (pusher) {
      if (allCameras.length > 0) {
        const channel = pusher.subscribe("camera-action");
        bindSignal(channel);
        return () => {
          pusher.unsubscribe("camera-action");
        };
      }
    }
  }, [allCameras, pusher]);

  useEffect(() => {
    if (pusher) {
      const channel_camera = pusher.subscribe("login");

      channel_camera.bind("camera", (data: any) => {
        dispatch(updateCameraState(data.cameraDetails));
      });

      return () => {
        pusher.unsubscribe("login");
      };
    }
  }, [pusher]);

  const bindSignal = (channel: any) => {
    channel.bind("signal", (data: any) => {
      if (data.action === "play") {
        setTimeout(() => {
          const activeCameraIndex = allCameras.findIndex(
            (x) => x.channel_name === data.channel_name
          );
          if (activeCameraIndex !== -1) {
            if (allCameras[activeCameraIndex].status === "play") return;

            const newActiveCamera = [...allCameras];
            newActiveCamera[activeCameraIndex] = {
              ...newActiveCamera[activeCameraIndex],
              status: "play",
            };

            dispatch(updateAllCamera(newActiveCamera as any));
            // setAllCameras(newActiveCamera);
          }
        }, 1000);
      } else if (data.action === "stop") {
        setTimeout(() => {
          const activeCameraIndex = allCameras.findIndex(
            (x) => x.channel_name === data.channel_name
          );
          if (activeCameraIndex !== -1) {
            if (
              allCameras[activeCameraIndex].status === "stop" &&
              !allCameras[activeCameraIndex].sos
            )
              return;
            const newActiveCamera = [...allCameras];
            newActiveCamera[activeCameraIndex] = {
              ...newActiveCamera[activeCameraIndex],
              status: "stop",
              sos: false,
            };

            dispatch(updateAllCamera(newActiveCamera as any));
          }
        }, 1000);
      } else if (data.action === "sos_on") {
        const activeCameraIndex = allCameras.findIndex(
          (x) => x.channel_name === data.channel_name
        );

        if (activeCameraIndex !== -1) {
          if (allCameras[activeCameraIndex].sos) return;
          const newActiveCamera = [...allCameras];
          newActiveCamera[activeCameraIndex] = {
            ...newActiveCamera[activeCameraIndex],
            sos: true,
          };
          toast.error(
            "SOS triggered for camera " +
              newActiveCamera[activeCameraIndex].name +
              "!"
          );
          dispatch(updateAllCamera(newActiveCamera as any));
        }
      } else if (data.action === "sos_off") {
        const activeCameraIndex = allCameras.findIndex(
          (x) => x.channel_name === data.channel_name
        );
        if (activeCameraIndex !== -1) {
          if (!allCameras[activeCameraIndex].sos) return;
          const newActiveCamera = [...allCameras];

          newActiveCamera[activeCameraIndex] = {
            ...newActiveCamera[activeCameraIndex],
            sos: false,
          };

          dispatch(updateAllCamera(newActiveCamera as any));
        }
      }
    });
  };
  return (
    <>
      <Navigation />
      <div className="site-main">
        <Header />
        <div className={`site-body ${(showNavigation && "disable") || ""}`}>
          {<Outlet />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
