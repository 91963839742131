import Hls from "hls.js";
import Network from "../Network/Network";
import API from "../Network/API";

export const getVideoStreamURL = async (
  channel_name: string,
  videoRef: React.RefObject<HTMLVideoElement>
) => {
  try {
    const resp = await Network.get(API.getHLSStreamingSessionURL, {
      channel_name: channel_name,
    });

    const hlsStream = resp.data.hlsStream;

    if (Hls.isSupported()) {
      const hls = new Hls();
      // @ts-ignore
      hls.loadSource(hlsStream?.HLSStreamingSessionURL);
      // @ts-ignore
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        // @ts-ignore
        videoRef.current.play();
      });
      // @ts-ignore
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      // @ts-ignore

      videoRef.current.src = hlsStream.HLSStreamingSessionURL;
      // @ts-ignore

      videoRef.current.addEventListener("loadedmetadata", () => {
        // @ts-ignore
        videoRef.current.play();
      });
    }
  } catch (error) {
    console.error("Error fetching video stream URL:", error);
  }
};
