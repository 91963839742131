const arrowUp = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292866 7.70708C-0.0976433 7.31654 -0.0976188 6.68338 0.29292 6.29287L6.29338 0.292866C6.68392 -0.0976428 7.31709 -0.097619 7.7076 0.292921L13.7071 6.29292C14.0976 6.68346 14.0976 7.31662 13.7071 7.70713C13.3165 8.09764 12.6834 8.09762 12.2929 7.70708L7.00041 2.41421L1.70708 7.70713C1.31654 8.09764 0.683375 8.09762 0.292866 7.70708Z"
      fill="#2E5A76"
    />
  </svg>
);

const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7071 4.29292C15.0976 4.68346 15.0976 5.31662 14.7071 5.70713L8.70662 11.7071C8.31608 12.0976 7.68291 12.0976 7.2924 11.7071L1.29287 5.70708C0.902357 5.31654 0.902381 4.68337 1.29292 4.29287C1.68346 3.90236 2.31662 3.90238 2.70713 4.29292L7.99959 9.58579L13.2929 4.29287C13.6835 3.90236 14.3166 3.90238 14.7071 4.29292Z"
      fill="#2E5A76"
    />
  </svg>
);

export const AppTableExpandRowSettings = {
  parentClassName: "parent-expand-tr",
  className: "child-expand-tr",
  showExpandColumn: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }: any) => {
    if (isAnyExpands) {
      return arrowUp;
    }
    return arrowDown;
  },
  expandColumnRenderer: ({ expanded }: any) => {
    if (expanded) {
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            {arrowUp}
          </div>
        </>
      );
    }
    return (
      <>
        <div className="d-flex align-items-center justify-content-md-center">
          <span className="me-2">Details</span>
          {arrowDown}
        </div>
      </>
    );
  },
};
