import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import AppTableAction from "../../../components/AppTableAction";
import AppSearch from "../../../components/AppSearch";
import { Dropdown } from "react-bootstrap";
import IconFilter from "@/images/icon__filter.svg";
import AppButton from "../../../components/AppButton";
import AppTable from "../../../components/AppTable";
import IconClearFilter from "@/images/icon__clear-filter.svg";

import API from "../../../Network/API";
import Network from "../../../Network/Network";
import { sortOptions, filterOptions } from "../../../utils/constant";

import {
  updateUserRegistrationFormState,
  //userRegistrationFormStateSubmitted,
} from "../../../store/slices/user/userRegistrationFormSlice";
import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";
import AppDeleteConfirmationModal from "../../../shared/AppDeleteConfirmationModal";

const UserManagement: React.FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, _setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setFilterOption] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [showUpdateStatusConfirmModal, setShowUpdateStatusModal] =
    useState(false);
  const [toBeUpdatedUserStatus, setToBeUpdatedUserStatus] = useState({
    userId: "",
    status: "",
  });

  const onChangeSearchTerm = (termSearched: any) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const getAllUsers = async () => {
    dispatch(startLoading());
    try {
      const users = await Network.get(API.userList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortOption,
        active: filterOption,
      });
      setAllUsers(
        users.data.users.map((user: any) => ({
          ...user,
          hotel_name: user?.hotels
            ?.map((owner: any) => owner?.user_hotel?.name)
            ?.join(", "),
        }))
      );
      setTotal(users.data.total_count);
      dispatch(endLoading());
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  const getAllRoles = async () => {
    try {
      const roles = await Network.get(API.fetchRoles);
      setAllRoles(roles.data.roles);
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [page, perPage, searchTerm, sortOption, filterOption]);

  useEffect(() => {
    getAllRoles();
    // dispatch(userRegistrationFormStateSubmitted());
    dispatch({ type: "RESET_FORM" });
  }, []);

  const edituser = async (user: any) => {
    dispatch(startLoading());
    try {
      const userDetails = await Network.get(
        `${API.fetchUserDataById}?userId=${user._id}`
      );

      const roleList = await Network.get(`${API.fetchRoles}`);

      const result = userDetails?.data?.user;

      let assignedHotel: any = [];

      if (result?.hotel?.length > 0) {
        const hotelList = await Network.get(`${API.hotelList}`);
        result?.hotel.forEach((element: any) =>
          hotelList?.data?.hotels.filter((e: any) => {
            if (e._id === element) {
              assignedHotel.push({ label: e.name, value: e._id });
            }
          })
        );
      }

      const assignedRole = roleList?.data?.roles.filter((e: any) => {
        return e.value === result?.role;
      });

      const updateHotelStateValue: any = {
        _id: user._id,
        is_step_1_complete: true,
        is_step_2_complete: true,
        is_step_3_complete: true,
        user_form_type: "edit",
        name: result?.name,
        email: result?.email,
        phone_no: result?.phone_no,
        employee_id: result?.employee_id,
        image: result?.image,
        role: assignedRole[0],
        resetOnLogin: result?.first_login,
        hotels: assignedHotel,
        is_all_state_reset: false,
      };
      dispatch(updateUserRegistrationFormState(updateHotelStateValue));
      dispatch(endLoading());
      navigate(`/user-management/edit/${result?._id}?step=1`);
    } catch (e: any) {
      console.log(e);
      toast.error(e.response.data.message);
      dispatch(endLoading());
    }
  };

  const columns = [
    {
      dataField: "account_no",
      text: "Account ID",
      headerAttrs: { title: "Sort By:" },
      attrs: { title: "Account ID" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "date",
      text: "Reg. Date",
      attrs: { title: "Date" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, allUsers: any) => (
        <>{moment(allUsers?.createdAt).format("MMMM DD, YYYY")}</>
      ),
    },
    {
      dataField: "name",
      text: "User Name",
      attrs: { title: "User Name" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "email",
      text: "Email",
      attrs: { title: "Email" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "camera",
      text: "Camera",
      attrs: { title: "Camera" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => (
        <>
          {row?.camera
            ? row?.camera?.name
              ? row?.camera?.name
              : row?.camera?.channel_name
            : " - "}
        </>
      ),
    },
    {
      dataField: "hotel_name",
      text: "Building Name",
      attrs: { title: "Building Name" },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "role",
      text: "Role",
      attrs: { title: "Role" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, allUsers: any) => (
        <span className="bg-primary px-3 py-1 rounded color-white text--nowrap">
          {
            (
              allRoles?.find(
                (role: any) => role.value === allUsers?.role
              ) as any
            )?.label
          }
        </span>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      attrs: { title: "Action" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <>
          {row.is_active ? (
            <AppTableAction
              row={row}
              onEdit={() => edituser(row)}
              onUpdateStatus={() => {
                setToBeUpdatedUserStatus({ userId: row._id, status: "active" });
                setShowUpdateStatusModal(true);
              }}
              onUpdateStatusVisibility={row._id}
            />
          ) : (
            <AppTableAction
              row={row}
              onUpdateStatus={() => {
                setToBeUpdatedUserStatus({
                  userId: row._id,
                  status: "inactive",
                });
                setShowUpdateStatusModal(true);
              }}
              onUpdateStatusVisibility={row._id}
            />
          )}
        </>
      ),
      headerStyle: {
        width: "120px",
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const handleUpdateUserStatus = async () => {
    setShowUpdateStatusModal(false);
    dispatch(startLoading());
    try {
      const response: any = await Network.get(
        `${
          toBeUpdatedUserStatus?.status === "active"
            ? API.deactivateUser
            : API.activateUser
        }`,
        { userId: toBeUpdatedUserStatus?.userId }
      );
      if (response !== undefined && response?.status === 200) {
        toast.success(response?.data?.message);

        setToBeUpdatedUserStatus({ userId: "", status: "" });
        getAllUsers();
        dispatch(endLoading());
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      dispatch(endLoading());
    }
  };

  const handleResetFilter = () => {
    setShowResetIcon(false);
    setSortOption("");
    setFilterOption(true);
  };

  return (
    <>
      <h1>{title}</h1>
      <div className="d-md-flex align-items-center mb-4">
        <AppSearch value={searchTerm} onChangeSearchTerm={onChangeSearchTerm} />
        <div className="ms-auto d-flex align-items-center mt-3 mt-md-0 me-md-3">
          <Dropdown align={"end"} className="w-50 w-md-auto">
            <Dropdown.Toggle className="w-100">
              {sortOptions.find((option) => option.value === sortOption)
                ?.label || "Sort"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {sortOptions.map((option) => (
                <Dropdown.Item
                  key={option?.value}
                  as={"button"}
                  onClick={() => {
                    setSortOption(option.value);
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown align={"end"} className="ms-3 w-50 w-md-auto">
            <Dropdown.Toggle className="has-icon w-100">
              {filterOptions.find((option) => option.value === filterOption)
                ?.label || "Filter"}{" "}
              <img src={IconFilter} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filterOptions.map((option) => (
                <Dropdown.Item
                  key={option?.label}
                  as={"button"}
                  onClick={() => {
                    setPage(1);
                    setFilterOption(option.value);
                    if (option.value === true) return;
                    setShowResetIcon(true);
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mt-3 mt-md-0">
          {(showResetIcon && (
            <AppButton className="danger me-3" onClick={handleResetFilter}>
              <img className="img-h-20" src={IconClearFilter} alt="" />
            </AppButton>
          )) ||
            null}
          <AppButton
            className="primary w-100 w-md-auto"
            onClick={() => navigate("/user-management/add?step=1")}
          >
            + Add User
          </AppButton>
        </div>
      </div>
      <AppTable
        classes="no-filter"
        headerClasses="empty"
        keyField="_id"
        columns={columns}
        rows={allUsers || []}
        // hasPagination={true}
        // paginationPerPageItem={perPage}
        // totalSize={allUsers?.length || 0}
        pagination
        totalCount={total}
        perPage={perPage}
        page={page}
        onPageChange={setPage}
      />
      {(showUpdateStatusConfirmModal && (
        <AppDeleteConfirmationModal
          show={showUpdateStatusConfirmModal}
          onClose={() => setShowUpdateStatusModal(false)}
          onConfirm={handleUpdateUserStatus}
          type={(filterOption && "deactivate") || "activate"}
        />
      )) ||
        null}
    </>
  );
};

export default UserManagement;
