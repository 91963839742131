import React from "react";
import BootstrapTable from "react-bootstrap-table-nextgen";
// import paginationFactory from "react-bootstrap-table-nextgen-paginator";
import "react-bootstrap-table-nextgen/dist/react-bootstrap-table-nextgen.min.css";
import ReactPaginate from "react-paginate";

interface AppTableProps {
  classes?: any;
  headerClasses?: any;
  columns: any;
  rows: any;
  keyField: string;
  selectRow?: any;
  expandRow?: any;
  errorMessage?: any;
  filter?: any;
  // hasPagination?: any;
  // paginationPerPageItem?: any;
  // totalSize?: any;
  cellEditCallback?: any;
  pagination?: boolean;
  onPageChange?: (selected: number) => void | undefined;
  totalCount?: number | null;
  perPage?: number | null;
  page?: number | null;
}

const AppTable: React.FC<AppTableProps> = ({
  classes,
  headerClasses,
  columns,
  rows,
  keyField,
  selectRow,
  expandRow,
  errorMessage,
  filter,
  pagination,
  onPageChange,
  totalCount,
  perPage,
  page,
}) => {
  return (
    <>
      <BootstrapTable
        classes={classes}
        headerClasses={headerClasses}
        keyField={keyField}
        columns={columns}
        data={rows}
        expandRow={expandRow}
        selectRow={selectRow}
        bordered={false}
        striped={false}
        noDataIndication={
          <div className="color-light weight-400 text-center w-100">
            {errorMessage ? errorMessage : "No Items found"}
          </div>
        }
        filter={filter}
        //pagination={hasPagination ? paginationFactory(options) : undefined}
      />
      {(pagination && (
        <div className="react-bootstrap-table-paginate">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={({ selected }) =>
              onPageChange && onPageChange(selected + 1)
            }
            pageRangeDisplayed={3}
            pageCount={
              (totalCount && perPage && Math.ceil(totalCount / perPage)) || 0
            }
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            forcePage={(page && page - 1) || 0}
          />
        </div>
      )) ||
        null}
    </>
  );
};

export default AppTable;
