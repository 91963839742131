import { CSSProperties, FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AppButton from "../../../components/AppButton";
import IconCamera from "@/images/icon__camera--white.svg";
import IconUserManagement from "@/images/icon__user-management--white.svg";

const styleRounded: CSSProperties = {
  width: "2rem",
  height: "2rem",
  borderRadius: "50%",
  lineHeight: "2rem",
  textAlign: "center",
};

const styleIcon: CSSProperties = {
  width: "2.5rem",
  height: "2.5rem",
  borderRadius: "50%",
};

const GetStarted: FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);

  return (
    <div className="row py-xl-3 text-center">
      <div className="col-md-8 col-lg-6 mx-auto">
        <h1 className="weight-500 mb-3">{title}</h1>
        <p className="mb-5 text--lrg weight-500">
          Welcome to Helpi! Follow these simple steps to get started and make
          the most out of your experience.
        </p>
      </div>
      <div className="w-100"></div>
      <div className="col-lg-10 col-xl-7 mx-auto d-flex flex-column align-items-center mb-5">
        <h4 className="d-flex align-items-center color-primary mb-2">
          Step
          <span className="bg-primary color-white ms-2" style={styleRounded}>
            1
          </span>
        </h4>
        <h3>Pairing Device</h3>
        <div className="bg-background--light w-100 rounded--20 p-4 text--lrg d-flex align-items-center justify-content-center flex-wrap">
          Please navigate the
          <strong className="weight-600 mx-2">Support Tab</strong>
          <span
            className="bg-primary rounded-pill d-flex me-2 my-2 my-md-0"
            style={styleIcon}
          >
            <img className="m-auto img-h-16" src={IconCamera} alt="" />
          </span>
          then Click on
          <AppButton className="primary my-2 my-md-0 mx-2 py-2 rounded readOnly">
            + Add Camera
          </AppButton>
          and follow the instructions to register your camera(s).
        </div>
      </div>
      <div className="w-100"></div>
      <div className="col-lg-10 col-xl-7 mx-auto d-flex flex-column align-items-center mb-5">
        <h4 className="d-flex align-items-center color-primary mb-2">
          Step
          <span className="bg-primary color-white ms-2" style={styleRounded}>
            2
          </span>
        </h4>
        <h3>Register User(s)</h3>
        <div className="bg-background--light w-100 rounded--20 p-4 text--lrg d-flex align-items-center justify-content-center flex-wrap">
          Please navigate the
          <strong className="weight-600 mx-2">User Management Tab</strong>
          <span
            className="bg-primary rounded-pill d-flex me-2 my-2 my-md-0"
            style={styleIcon}
          >
            <img className="m-auto img-h-20" src={IconUserManagement} alt="" />
          </span>
          then Click on
          <AppButton className="primary mx-2 py-2 rounded readOnly my-2 my-md-0">
            + Add User
          </AppButton>
          and follow the instructions to register your user(s).
        </div>
      </div>
      <div className="w-100"></div>
      <div className="col-lg-10 col-xl-7 mx-auto d-flex flex-column align-items-center">
        <h4 className="d-flex align-items-center color-primary mb-2">
          Step
          <span className="bg-primary color-white ms-2" style={styleRounded}>
            3
          </span>
        </h4>
        <h3>Streaming</h3>
        <div className="bg-background--light w-100 rounded--20 p-4 text--lrg d-flex align-items-center justify-content-center flex-wrap">
          Please open your android application and enter
          <strong className="weight-600 mx-2">Account ID</strong>
          to login and start streaming.
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
