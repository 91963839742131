import React, { useState } from "react";
import IconSignIn from "@/images/icon__signin.svg";
import { toast } from "react-toastify";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { Link } from "react-router-dom";
import Main from "./Main";
import { useDispatch } from "react-redux";
import { signIn } from "../../store/slices/user/userSlice";
import { AppDispatch } from "../../store/store";
import { emailRegex } from "../../utils/Regex";

import FirstLoginChangePasswordModal from "../authentication/FirstLoginChangePasswordModal";

import API from "../../Network/API";
import Network from "../../Network/Network";

interface SignInFormTypes {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [firstLogin, setFirstLogin] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState();
  const [loggedInData, setLoggedInData] = useState();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormTypes>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<SignInFormTypes> = async (data) => {
    try {
      const response = await Network.post(API.login, data);
      const loginData = response?.data;
      if (loginData.first_login) {
        setLoggedInData({ ...loginData, isLoggedIn: true });
        dispatch(
          //@ts-ignore
          signIn({
            token: loginData.token,
            refresh_token: loginData.refresh_token,
          })
        );
        setChangePasswordData(loginData);
        setFirstLogin(true);
      } else {
        dispatch(signIn({ ...loginData, isLoggedIn: true }));
        toast.success("Logged in");
      }
    } catch (e: any) {
      toast.error(e.response.data.message);
      console.error("e", e);
    }
  };

  const OnChangePassword = (afterSaveStatus: any) => {
    setFirstLogin(false);
    if (afterSaveStatus?.status == 200) {
      const data = {
        ...afterSaveStatus.data,
        isLoggedIn: true,
      };
      dispatch(signIn(data));
    } else {
      dispatch(signIn(loggedInData));
      toast.success("Logged in");
    }
  };

  return (
    <>
      <Main>
        <h1 className="mb-2">Sign In to your workspace</h1>
        <p className="color-light text--lrg mb-4 pb-md-2">
          Login using the <strong>Account ID/Email</strong> and{" "}
          <strong>Password</strong> <br className="d-none d-xl-inline" />
          associated with your account.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: (value: any) => {
                  if (value.indexOf("@") > -1) {
                    const check = emailRegex.test(value);
                    if (!check) {
                      return "Provide proper email";
                    }
                  } else {
                    if (isNaN(value)) {
                      return "Account Id should be numerical";
                    } else {
                      if (value.length != 7) {
                        return "Account Id should be of 7 digit";
                      }
                    }
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Account ID/Email"
                  isRequired
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            {errors?.email?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.email?.type === "validate" && (
              <span className="form-status error">
                {errors?.email?.message}
              </span>
            )}
          </div>
          <div className="mb-4 pb-md-2">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Password"
                  isRequired
                  isPassword
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            <div className="d-flex align-items-center mt-1">
              {errors?.password?.type === "required" && (
                <span className="form-status error mt-0">
                  This field is required
                </span>
              )}
              <Link
                className="ms-auto weight-500 text--sml me-3.5"
                to={"/forgot-password"}
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <AppButton custom type="submit">
              Sign In <img src={IconSignIn} className="ms-3" alt="" />
            </AppButton>
          </div>
        </form>
      </Main>
      {(firstLogin && (
        <FirstLoginChangePasswordModal
          show={firstLogin}
          onClose={OnChangePassword}
          data={changePasswordData}
          type={"first_login"}
        />
      )) ||
        null}
    </>
  );
};

export default SignIn;
