import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import PlaceInfo from "./PlaceInfo";
import ContactInfo from "./ContactInfo";
import AreaDetails from "./AreaDetails";
import RegistrationCompleted from "./RegistrationCompleted";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { updateHotelState } from "../../../../store/slices/hotel/hotelSlice";
import AppButton from "../../../../components/AppButton";
import { backIcon } from "../../../../layout/Header";

const AddHotelDashboard: React.FC = ({}) => {
  const hotelState = useSelector((state: RootState) => state?.hotel);
  const [searchParams] = useSearchParams();
  const step = Number(searchParams.get("step"));
  let { hotelId } = useParams();
  const [formType, setFormType] = useState("add");
  const dispatch: AppDispatch = useDispatch();
  const { title, backURL } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const triggerHotelStep1FormSubmitRef = useRef<any>();
  const triggerHotelStep2FormSubmitRef = useRef<any>();
  const triggerHotelStep3FormSubmitRef = useRef<any>();

  useEffect(() => {
    if (hotelId) {
      setFormType("edit");
      const updateHotelStateValue: any = {
        hotel_form_type: "edit",
      };
      dispatch(updateHotelState(updateHotelStateValue));
    } else {
      dispatch({ type: "RESET_FORM" });
    }
  }, []);

  const checkHotelFormValidationOfCurrentForm = async (urlLink: string) => {
    if (step === 1) {
      //@ts-ignore
      const check =
        await triggerHotelStep1FormSubmitRef.current?.triggerHotelStep1FormValidation();
      if (check) {
        navigate(urlLink);
      }
    } else if (step === 2) {
      //@ts-ignore
      const check =
        await triggerHotelStep2FormSubmitRef.current?.triggerHotelStep2FormValidation();
      if (check) {
        navigate(urlLink);
      }
    } else if (step === 3) {
      //@ts-ignore
      const check =
        await triggerHotelStep3FormSubmitRef.current?.triggerHotelStep3FormValidation();
      if (check) {
        navigate(urlLink);
      }
    } else if (step === 4) {
      navigate(urlLink);
    }
  };

  return (
    <>
      <div className="row h-md-100">
        <div className="col-md-5 col-lg-4 mb-5 mb-md-0">
          <div className="row h-100 justify-content-center">
            <div className="col-xl-8">
              {(!!backURL && (
                <h1 className="mb-5 d-flex d-md-none align-items-center">
                  <AppButton
                    custom
                    className="button--back"
                    onClick={() => navigate(backURL)}
                  >
                    {backIcon}
                  </AppButton>
                  {title}
                </h1>
              )) ||
                null}
              {formType === "add" ? (
                <ul className="steps">
                  <li
                    className={
                      hotelState.is_step_1_complete ? "completed" : "current"
                    }
                  >
                    <span
                      className={(step === 1 && "current_step") || ""}
                      onClick={() =>
                        checkHotelFormValidationOfCurrentForm(
                          `/hotel-management/add?step=1`
                        )
                      }
                    >
                      Place Info
                    </span>
                  </li>
                  {hotelState.is_step_1_complete ? (
                    <li
                      className={
                        hotelState.is_step_2_complete ? "completed" : "current"
                      }
                    >
                      <span
                        className={(step === 2 && "current_step") || ""}
                        onClick={() =>
                          checkHotelFormValidationOfCurrentForm(
                            `/hotel-management/add?step=2`
                          )
                        }
                      >
                        Contact Info
                      </span>
                    </li>
                  ) : (
                    <li className="">
                      <span>Contact Info</span>
                    </li>
                  )}

                  {hotelState.is_step_1_complete &&
                  hotelState.is_step_2_complete ? (
                    <li
                      className={
                        hotelState.is_step_3_complete ? "completed" : "current"
                      }
                    >
                      <span
                        className={(step === 3 && "current_step") || ""}
                        onClick={() =>
                          checkHotelFormValidationOfCurrentForm(
                            `/hotel-management/add?step=3`
                          )
                        }
                      >
                        Area Details
                      </span>
                    </li>
                  ) : (
                    <li className="">
                      <span>Area Details</span>
                    </li>
                  )}

                  {hotelState.is_step_1_complete &&
                  hotelState.is_step_2_complete &&
                  hotelState.is_step_3_complete ? (
                    <li
                      className={
                        hotelState.is_step_1_complete &&
                        hotelState.is_step_2_complete &&
                        hotelState.is_step_3_complete
                          ? "current completed"
                          : ""
                      }
                    >
                      <span
                        className={(step === 4 && "current_step") || ""}
                        onClick={() =>
                          checkHotelFormValidationOfCurrentForm(
                            `/hotel-management/add?step=4`
                          )
                        }
                      >
                        Registration Completed
                      </span>
                    </li>
                  ) : (
                    <li className="">
                      <span>Registration Completed</span>
                    </li>
                  )}
                </ul>
              ) : (
                <ul className="steps">
                  <li className="completed">
                    <span
                      className={(step === 1 && "current_step") || ""}
                      onClick={() =>
                        checkHotelFormValidationOfCurrentForm(
                          `/hotel-management/edit/${hotelId}?step=1`
                        )
                      }
                    >
                      Place Info
                    </span>
                  </li>

                  <li className="completed">
                    <span
                      className={(step === 2 && "current_step") || ""}
                      onClick={() =>
                        checkHotelFormValidationOfCurrentForm(
                          `/hotel-management/edit/${hotelId}?step=2`
                        )
                      }
                    >
                      Contact Info
                    </span>
                  </li>

                  <li className="completed">
                    <span
                      className={(step === 3 && "current_step") || ""}
                      onClick={() =>
                        checkHotelFormValidationOfCurrentForm(
                          `/hotel-management/edit/${hotelId}?step=3`
                        )
                      }
                    >
                      Area Details
                    </span>
                  </li>

                  {/* <li className="completed">
                    <Link to={`/hotel-management/edit/${hotelId}?step=4`}>
                      Registration Completed
                    </Link>
                  </li> */}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-7 col-lg-8 px-md-4 px-lg-5">
          <div className="d-flex align-items-center h-100 w-100">
            <div className="flex-grow-1 w-100">
              {step === 1 ? (
                <PlaceInfo ref={triggerHotelStep1FormSubmitRef} />
              ) : step === 2 ? (
                <ContactInfo ref={triggerHotelStep2FormSubmitRef} />
              ) : step === 3 ? (
                <AreaDetails ref={triggerHotelStep3FormSubmitRef} />
              ) : step === 4 ? (
                <RegistrationCompleted />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHotelDashboard;
