import React from "react";

interface AppCheckboxTypes {
  className?: string;
  disabled?: boolean;
  checked: boolean;
  id?: string;
  label?: string;
  value?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppCheckbox: React.FC<AppCheckboxTypes> = ({
  className,
  disabled,
  checked,
  id,
  label,
  onChange,
}) => {
  return (
    <div
      className={`form-checkbox  ${(className && className) || ""}`}
      key={id}
    >
      <input
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        id={id}
      />
      <span className="icon"></span>
      {label ? (
        <label htmlFor={id} className="form-label">
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default AppCheckbox;
