import React from "react";
import IconRegisterdCameras from "@/images/icon__registered-cameras.svg";
import IconActiveCameras from "@/images/icon__active-cameras.svg";
import IconSOSCalls from "@/images/icon__sos-calls.svg";
import { Link } from "react-router-dom";

const iconWrapperStyle = {
  width: "4rem",
  height: "4rem",
};

type Props = {
  totalCameras: number;
  activeCameras: number;
  sosCameras: number;
};

const CameraInfo: React.FC<Props> = ({
  totalCameras,
  activeCameras,
  sosCameras,
}) => {
  return (
    <div className="row stats mb-4 pb-md-3">
      <div className="col-md-4 mb-2 mb-md-0">
        <div className="bg-background--light rounded--24 py-3 px-4 d-flex align-items-center">
          <span
            className="bg-background--dark rounded-pill d-flex me-3 flex-shrink-0"
            style={iconWrapperStyle}
          >
            <img className="m-auto" src={IconRegisterdCameras} alt="" />
          </span>
          <p className="text--lrg weight-500 m-0">
            Registered Active Cameras
            <span className="text--xlrg weight-600 d-block">
              {totalCameras}
            </span>
          </p>
          <Link
            className="stretched-link"
            to={"/camera-management/registered-cameras"}
          />
        </div>
      </div>
      <div className="col-md-4 mb-2 mb-md-0">
        <div className="bg-background--light rounded--24 py-3 px-4 d-flex align-items-center">
          <span
            className="bg-background--dark rounded-pill d-flex me-3 flex-shrink-0"
            style={iconWrapperStyle}
          >
            <img className="m-auto" src={IconActiveCameras} alt="" />
          </span>
          <p className="text--lrg weight-500 m-0">
            In-use Cameras
            <span className="text--xlrg weight-600 d-block">
              {activeCameras}
            </span>
          </p>
          <Link
            className="stretched-link"
            to={
              activeCameras > 0 ? "/camera-management/lists?type=active" : "#"
            }
          />
        </div>
      </div>
      <div className="col-md-4">
        <div className="bg-background--light rounded--24 py-3 px-4 d-flex align-items-center">
          <span
            className="bg-background--dark rounded-pill d-flex me-3 flex-shrink-0"
            style={iconWrapperStyle}
          >
            <img className="m-auto" src={IconSOSCalls} alt="" />
          </span>
          <p className="text--lrg weight-500 m-0">
            SOS Calls
            <span className="text--xlrg weight-600 d-block">{sosCameras}</span>
          </p>
          <Link
            className="stretched-link"
            to={sosCameras > 0 ? "/camera-management/lists?type=sos" : "#"}
          />
        </div>
      </div>
    </div>
  );
};

export default CameraInfo;
