import React from "react";

interface AppInputTypes {
  className?: string;
  value: string | null | undefined;
  label: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isRequired?: boolean;
}

const AppTextarea: React.FC<AppInputTypes> = ({
  className,
  value,
  label,
  disabled,
  readOnly,
  placeholder,
  onChange,
  isRequired = false,
  ...props
}) => {
  return (
    <div className="form-floating">
      <textarea
        className={`form-control form-textarea ${
          (!!className && className) || ""
        }`}
        value={value || ""}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder || ""}
        onChange={onChange}
        {...props}
      />
      <label>
        {label} {(isRequired && <sup>*</sup>) || null}
      </label>
    </div>
  );
};

export default AppTextarea;
