import React, { useEffect, useRef } from "react";
// import IconCamCall from "@/images/icon__camera--call.svg";
// import AppButton from "../../../components/AppButton";
import { Link } from "react-router-dom";
import moment from "moment";

import { getVideoStreamURL } from "../../../utils/hls";
import { cameraManufacturers } from "../../../utils/constant";

type Props = {
  streamName: string;
  cleanerId: string;
  cleanerName: string;
  cameraManufacturer: string | null;
  cameraModel: string | null;
  deviceType: string | null;
};

const CameraSOS: React.FC<Props> = ({
  streamName,
  cleanerId,
  cleanerName,
  cameraManufacturer,
  cameraModel,
  deviceType,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    getVideoStreamURL(streamName, videoRef);
  }, [streamName]);

  return (
    <div
      className="block"
      style={
        {
          // backgroundImage: `url(${SampleGraphics})`,
        }
      }
    >
      <video
        ref={videoRef}
        style={{
          rotate: !deviceType
            ? cameraManufacturer?.toLowerCase() === "motorola"
              ? cameraModel === "V500" || cameraModel === "VB400"
                ? "0deg"
                : "90deg"
              : cameraManufacturers.includes(
                  cameraManufacturer?.toLowerCase() || ""
                )
              ? "0deg"
              : "90deg"
            : deviceType === "mobile"
            ? "90deg"
            : "0deg",
        }}
      ></video>
      <div className="block__header">
        <span className="live text-truncate">Live</span>
        <span className="ms-auto">{cleanerName}</span>
        {/* <AppButton custom>
          <img src={IconCamCall} alt="" />
        </AppButton> */}
      </div>
      <span className="alertSOS">
        <span>SOS</span>
      </span>
      <div className="block__footer">
        <span className="flex-grow-1 me-4 text-truncate">
          {moment().format("MMMM DD, YYYY")}
        </span>
        {/* <span className="ms-auto flex-shrink-0">Room 101</span> */}
        <span className="ms-auto flex-shrink-0">
          {moment().format("hh:mm a")}
        </span>
      </div>
      <Link
        className="stretched-link"
        to={
          "/camera-management/details/" +
          streamName +
          "?cleanerId=" +
          cleanerId +
          "&type=sos"
        }
      />
    </div>
  );
};

export default CameraSOS;
