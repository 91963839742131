import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AppDateTimePicker from "../../../components/AppDateTimePicker";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { toast } from "react-toastify";
import moment from "moment";

interface ModalTypes {
  show: boolean;
  onClose: () => void;
  task_assignment_id: string | null;
}

interface FormTypes {
  start_time: Date | null;
  end_time: Date | null;
}

const TaskDateTimeAssignmentModal: FC<ModalTypes> = ({
  show,
  onClose,
  task_assignment_id,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormTypes>();

  const fetchTaskAssignmentById = () => {
    try {
      Network.get(API.fetchTaskAssignmentById, { id: task_assignment_id })
        .then((resp: any) => {
          const { task_assignment } = resp.data;

          setValue("start_time", task_assignment?.start_time);
          setValue("end_time", task_assignment?.end_time);
        })
        .catch(console.log);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    task_assignment_id && fetchTaskAssignmentById();
  }, [task_assignment_id]);

  const onSubmit: SubmitHandler<FormTypes> = (data: any) => {
    const submitData = {
      _id: task_assignment_id,
      start_time: data?.start_time,
      end_time: data?.end_time,
    };

    try {
      Network.post(API.upsertTaskAssignment, submitData)
        .then(() => {
          toast.success("Task Assignment timestamp updated successfully!");
          onClose();
        })
        .catch((error: any) => {
          console.error(error);
          toast.error(error.response.data.message || "Something went wrong!");
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Start & End Date Time</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <Controller
                name="start_time"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppDateTimePicker
                    value={value}
                    onChange={(e) => {
                      if (e) {
                        if (
                          watch("end_time") &&
                          moment(e).isAfter(watch("end_time"))
                        ) {
                          setValue("end_time", e);
                        }
                      }
                      onChange(e);
                    }}
                    label="Start Time"
                  />
                )}
              />
              {errors?.start_time?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 m-0">
              <Controller
                name="end_time"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppDateTimePicker
                    value={value}
                    onChange={onChange}
                    label="End Time"
                    minDate={watch("start_time")}
                  />
                )}
              />
              {errors?.end_time?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppButton type="submit" className="primary">
            Save
          </AppButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TaskDateTimeAssignmentModal;
