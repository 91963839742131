import React, { useEffect, useState } from "react";
import RegisterCameraDetails from "./RegisterCameraDetails";
import RegisteringCameraDetails from "./RegisteringCameraDetails";
// import RegisteredCameraDetails from "./RegisteredCameraDetails";

import { useNavigate, useLocation } from "react-router-dom";
import AppButton from "../../../../components/AppButton";
import { backIcon } from "../../../../layout/Header";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { usePusher } from "../../../../components/PusherProvider";

const RegisterCameraDashboard: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { title, backURL } = useSelector((state: RootState) => state?.page);

  const [cameraState, setCameraState] = useState<any>(state);
  const pusher = usePusher();

  useEffect(() => {
    if (pusher) {
      if (cameraState?.camera_unique_id) {
        const channel = pusher.subscribe(cameraState?.camera_unique_id);
        channel.bind("pairing", () => {
          navigate(`/camera-management/view/${cameraState.camera_unique_id}`);
        });

        return () => {
          pusher.unsubscribe(cameraState?.camera_unique_id);
        };
      }
    }
  }, [cameraState?.camera_unique_id, pusher]);

  const onRegisteringCamera = (cameraData: any) => {
    setCameraState(cameraData);
  };

  return (
    <>
      {(!!backURL && (
        <h1 className="mb-4 d-flex d-md-none align-items-center">
          <AppButton
            custom
            className="button--back"
            onClick={() => navigate(backURL)}
          >
            {backIcon}
          </AppButton>
          {title !== "Registered Cameras" && title}
        </h1>
      )) ||
        null}
      {!cameraState?.camera_unique_id ? (
        <RegisterCameraDetails onRegisteringCamera={onRegisteringCamera} />
      ) : (
        <RegisteringCameraDetails cameraState={cameraState} />
      )}
    </>
  );
};

export default RegisterCameraDashboard;
