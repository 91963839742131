import { FC, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AppDateTimePicker from "../../../components/AppDateTimePicker";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { toast } from "react-toastify";
import moment from "moment";
import { CSVLink } from "react-csv";

interface ModalTypes {
  show: boolean;
  onClose: any;
  filter: string;
}

interface FormTypes {
  start_date: Date | null;
  end_date: Date | null;
}

const ExportDateModal: FC<ModalTypes> = ({ show, onClose, filter }) => {
  const triggerCSVExportRef = useRef<any>();

  //const [csvHeaders, setHeaders] = useState([{}]);
  const [exportData, setExportData] = useState<any>([]);
  const [fileName, setFileName] = useState<any>("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormTypes>();

  const convertMStoHHMMSS = (ms: number) => {
    const duration = moment.duration(ms);
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    const hr = (hours !== 0 && hours + ":") || "00:";
    const min = (minutes !== 0 && formattedMinutes + ":") || "00:";
    const sec = (seconds !== 0 && formattedSeconds) || "00";

    return `${hr}${min}${sec}`;
  };

  const onSubmit: SubmitHandler<FormTypes> = async (data: any) => {
    try {
      const response = await Network.get(API.fetchTaskAssignments, {
        filter: filter,
        type: "csv_export",
        start_time: moment(data?.start_date).toISOString(),
        end_time: moment(data?.end_date).toISOString(),
      });

      const export_data = response.data?.task_assignments?.map((e: any) => {
        return {
          task: e?.task?.task,
          area: e?.area?.area,
          room: e?.room_no,
          assignee: e?.user?.name ? e?.user?.name : e?.user?.email,
          assigned_date: e?.assigned_date,
          duration: convertMStoHHMMSS(
            moment(e?.end_time).diff(moment(e?.start_time))
          ),
          start_time: moment(e?.start_time).format("MMMM DD, YYYY hh:mm:ss a"),
          end_time: moment(e?.end_time).format("MMMM DD, YYYY hh:mm:ss a"),
          building: e?.hotel?.name,
          camera: e?.camera?.name ? e?.camera?.name : e?.camera?.channel_name,
          notes: e?.notes,
        };
      });
      const csvFileName = `${filter}-task-from-${moment(
        data?.start_date
      ).format("YYYY-MM-DD")}-to-${moment(data?.end_date).format(
        "YYYY-MM-DD"
      )}.csv`;
      setFileName(csvFileName);
      setExportData(export_data);

      if (!export_data?.length) {
        setFileName("");
        toast.info("No completed tasks to export!");
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setValue("start_date", moment().startOf("day").toDate());
    setValue("end_date", moment().endOf("day").toDate());
  }, []);

  useEffect(() => {
    if (exportData?.length) {
      triggerCSVExportRef.current?.link?.click();
      setFileName("");
      setExportData([]);
      onClose();
    }
  }, [exportData.length]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Export CSV</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <Controller
                name="start_date"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppDateTimePicker
                    value={value}
                    onChange={(e) => {
                      if (e) {
                        if (
                          watch("end_date") &&
                          moment(e).isAfter(watch("end_date"))
                        ) {
                          setValue("end_date", e);
                        }
                      }
                      onChange(e);
                    }}
                    label="Start Date"
                    showTimeInput={false}
                  />
                )}
              />
              {errors?.start_date?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 m-0">
              <Controller
                name="end_date"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { value, onChange } }) => (
                  <AppDateTimePicker
                    value={value}
                    onChange={onChange}
                    label="End Date"
                    minDate={watch("start_date")}
                    showTimeInput={false}
                  />
                )}
              />
              {errors?.end_date?.type === "required" && (
                <span className="form-status error">
                  This field is required
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CSVLink
            data={exportData}
            filename={fileName}
            ref={triggerCSVExportRef}
            className="m-0"
          >
            <AppButton type="submit" className="primary">
              Export
            </AppButton>
          </CSVLink>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ExportDateModal;
