import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import AppSearch from "../../../components/AppSearch";
import { Dropdown } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
// import IconFilter from "@/images/icon__filter.svg";
import moment from "moment";
import AppTableAction from "../../../components/AppTableAction";
import AppTable from "../../../components/AppTable";
import { AppTableExpandRowSettings } from "../../../utils/AppTableExpandSettings";
import AssignTaskModal from "./Assign/AssignTaskModal";
import TaskDateTimeAssignmentModal from "./TaskDateTimeAssignmentModal";
import ExportDateModal from "./ExportDateModal";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import {
  filterOptionsIsComplete,
  taskAssignmentSortOptions,
} from "../../../utils/constant";
import IconFilter from "@/images/icon__filter.svg";
import IconClearFilter from "@/images/icon__clear-filter.svg";

import {
  endLoading,
  startLoading,
} from "../../../store/slices/loader/loaderSlice";

const TaskAssignment: React.FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [showAssignTaskModal, setShowAssignTaskModal] = useState(false);
  const [assignTaskModalType, setAssignTaskModalType] = useState("Add");
  const [showAssignDateTimeModal, setShowAssignDateTimeModal] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [taskAssignments, setAllTaskAssignments] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [taskAssignmentId, setTaskAssignmentId] = useState<string | null>(null);
  const [filterOption, setFilterOption] = useState<string>("incomplete");
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [showExportDateModal, setShowExportDateModal] = useState(false);

  const perPage = 10;

  const onChangeSearchTerm = (termSearched: any) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    return str.substring(0, maxLength) + "...";
  };

  const convertMStoHHMMSS = (ms: number) => {
    const duration = moment.duration(ms);
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());
    const seconds = Math.floor(duration.seconds());

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

    const hr = (hours !== 0 && hours + ":") || "00:";
    const min = (minutes !== 0 && formattedMinutes + ":") || "00:";
    const sec = (seconds !== 0 && formattedSeconds) || "00";

    return `${hr}${min}${sec}`;
  };

  const columns = [
    {
      dataField: "task",
      text: "Task ",
      headerAttrs: { title: "Sort By:" },
      attrs: { title: "Task " },
      sort: false,
      isDummyField: true,
      headerStyle: {
        width: "300px",
      },
      formatter: (_: any, row: any) => (
        <>{(row?.task?.task && truncate(row.task.task, 50)) || "-"}</>
      ),
    },
    {
      dataField: "area_subarea",
      text: "Area (Subarea)",
      attrs: { title: "Area (Subarea)" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <>
          {row?.area?.area} {(row?.sub_area && `(${row?.sub_area})`) || null}
        </>
      ),
    },
    {
      dataField: "room_no",
      text: "Room No.",
      attrs: { title: "Room No." },
      sort: false,
      isDummyField: false,
    },
    {
      dataField: "assignee",
      text: "Assignee",
      attrs: { title: "Assignee" },
      sort: false,
      isDummyField: false,
      formatter: (_: any, row: any) => row?.user?.name || row?.user?.email,
    },
    {
      dataField: "assignment_date",
      text: "Assignment Date",
      attrs: { title: "Assignment Date" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <>{moment(row?.assigned_date).format("MMMM DD, YYYY")}</>
      ),
    },
    // {
    //   dataField: "start_end_datetime",
    //   text: "Start & End Time",
    //   attrs: { title: "Start & End Time" },
    //   sort: false,
    //   isDummyField: true,
    //   formatter: (_: any, row: any) => (
    //     <>
    //       {row?.start_time && row?.end_time
    //         ? `${moment(row?.start_time).format(
    //             "MMMM DD, YYYY hh:mm a"
    //           )} - ${moment(row?.end_time).format("MMMM DD, YYYY hh:mm a")}`
    //         : row?.start_time
    //         ? `${moment(row?.start_time).format("MMMM DD, YYYY hh:mm a")} - `
    //         : "-"}
    //     </>
    //   ),
    // },
    {
      dataField: "duration",
      text: "Duration",
      attrs: { title: "Duration" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <>
          {(row?.start_time &&
            row?.end_time &&
            convertMStoHHMMSS(
              moment(row?.end_time).diff(moment(row?.start_time))
            )) ||
            "-"}
        </>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      attrs: { title: "Action" },
      sort: false,
      isDummyField: true,
      formatter: (_: any, row: any) => (
        <AppTableAction
          row={row}
          onEdit={() => {
            setTaskAssignmentId(row?._id);
            setAssignTaskModalType("Edit");
            setShowAssignTaskModal(true);
          }}
          // onDelete={() => console.log(row)}
          onDateAssign={() => {
            setTaskAssignmentId(row?._id);
            setAssignTaskModalType("Edit");
            setShowAssignDateTimeModal(true);
          }}
        />
      ),
      headerStyle: {
        width: "120px",
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const expandRow = {
    ...AppTableExpandRowSettings,
    renderer: (row: any) => {
      return (
        <>
          <div className="row">
            <div className="col-md-12 mb-3">
              <h6 className="color-md-light mb-1">Task:</h6>
              {row?.task?.task || "-"}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="color-md-light mb-1">Start Time:</h6>
              {moment(row?.start_time).format("MMMM DD, YYYY hh:mm:ss a") ||
                "-"}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="color-md-light mb-1">End Time:</h6>
              {moment(row?.end_time).format("MMMM DD, YYYY hh:mm:ss a") || "-"}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="color-md-light mb-1">Building Name:</h6>
              {row?.hotel?.name || "-"}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="color-md-light mb-1">Assigned Camera:</h6>
              {row?.camera?.name || row?.camera?.camera_unique_id || "-"}
            </div>
            <div className="col-md-12">
              <h6 className="color-md-light mb-1">Note(s):</h6>
              {row?.notes || "-"}
            </div>
          </div>
        </>
      );
    },
  };

  const fetchTaskAssignments = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchTaskAssignments, {
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortOption,
        filter: filterOption,
      })
        .then((resp: any) => {
          setAllTaskAssignments(resp.data.task_assignments);
          setTotalCount(resp.data.total_count);
          dispatch(endLoading());
        })
        .catch((error: any) => {
          toast.error(error.response.data.message || "Something went wrong!");
          dispatch(endLoading());
        });
    } catch (error: any) {
      toast.error(error.response.data.message || "Something went wrong!");
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchTaskAssignments();
  }, [page, sortOption, searchTerm, filterOption]);

  const OnAddOrEditTaskAssignment = (afterSaveStatus: any) => {
    setShowAssignTaskModal(false);
    setAssignTaskModalType("Add");
    setTaskAssignmentId(null);
    if (afterSaveStatus === 200) {
      fetchTaskAssignments();
    }
  };

  const handleResetFilter = () => {
    setShowResetIcon(false);
    setSortOption("");
    setFilterOption("incomplete");
  };

  const onExportCSV = () => {
    setShowExportDateModal(true);
  };

  return (
    <>
      <h1>{title}</h1>
      <div className="d-lg-flex align-items-center mb-4">
        <AppSearch value={searchTerm} onChangeSearchTerm={onChangeSearchTerm} />
        <div className="ms-auto d-md-flex align-items-center mt-3 mt-lg-0">
          <div className="d-flex align-items-center mt-3 mt-md-0 me-md-3 flex-shrink-0">
            <Dropdown align={"end"} className="w-50 w-md-auto">
              <Dropdown.Toggle className="w-100">
                {taskAssignmentSortOptions.find(
                  (option) => option.value === sortOption
                )?.label || "Sort"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {taskAssignmentSortOptions.map((option) => (
                  <Dropdown.Item
                    key={option?.value}
                    as={"button"}
                    onClick={() => {
                      setSortOption(option.value);
                      setShowResetIcon(true);
                    }}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown align={"end"} className="ms-3 w-50 w-md-auto">
              <Dropdown.Toggle className="has-icon w-100">
                {filterOptionsIsComplete.find(
                  (option) => option.value === filterOption
                )?.label || "Filter"}{" "}
                <img src={IconFilter} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {filterOptionsIsComplete.map((option) => (
                  <Dropdown.Item
                    key={option?.value}
                    as={"button"}
                    onClick={() => {
                      setPage(1), setFilterOption(option.value);
                      if (option.value === "incomplete") return;
                      setShowResetIcon(true);
                    }}
                  >
                    {option.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex align-items-center mt-3 mt-md-0 flex-grow-1">
            {(showResetIcon && (
              <AppButton className="danger me-3" onClick={handleResetFilter}>
                <img className="img-h-20" src={IconClearFilter} alt="" />
              </AppButton>
            )) ||
              null}
            <AppButton
              className="primary w-100 w-md-auto ms-auto"
              onClick={() => setShowAssignTaskModal(true)}
            >
              Assign Task
            </AppButton>
          </div>
          {filterOption === "complete" ? (
            <AppButton
              className="primary w-100 w-md-auto ms-md-3 mt-3 mt-md-0 flex-shrink-0"
              type="button"
              onClick={onExportCSV}
            >
              Export CSV
            </AppButton>
          ) : null}
        </div>
      </div>
      <AppTable
        classes="no-filter"
        headerClasses="empty"
        keyField="_id"
        columns={columns}
        rows={taskAssignments || []}
        expandRow={expandRow}
        // hasPagination={true}
        // paginationPerPageItem={perPage}
        // totalSize={rows?.length || 0}
        pagination
        totalCount={totalCount}
        perPage={perPage}
        page={page}
        onPageChange={setPage}
      />

      {showAssignTaskModal && (
        <AssignTaskModal
          show={showAssignTaskModal}
          onClose={OnAddOrEditTaskAssignment}
          type={assignTaskModalType}
          task_assignment_id={taskAssignmentId}
        />
      )}

      {showAssignDateTimeModal && taskAssignmentId && (
        <TaskDateTimeAssignmentModal
          show={showAssignDateTimeModal}
          onClose={() => {
            setShowAssignDateTimeModal(false);
            setTaskAssignmentId(null);
            fetchTaskAssignments();
          }}
          task_assignment_id={taskAssignmentId}
        />
      )}
      {showExportDateModal && (
        <ExportDateModal
          show={showExportDateModal}
          onClose={() => setShowExportDateModal(false)}
          filter={filterOption}
        />
      )}
    </>
  );
};

export default TaskAssignment;
