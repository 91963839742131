import React from "react";
import Logo from "@/images/logo.svg";

interface MainTypes {
  children: React.ReactNode;
}

const Main: React.FC<MainTypes> = ({ children }) => {
  return (
    <>
      <div className="container h-100">
        <div className="row h-100 align-items-center">
          <div className="col-md-6 mb-4 mb-md-0">
            <div className="d-flex justify-content-center mb-4 pb-md-2">
              <img className="logo" src={Logo} alt="" />
            </div>
            <p className="text-center text--lrg m-0">
              {/* Helpi Cameras, your cleaners best friend! <br /> */}
              <strong>Safer Together</strong> <br />
              
              {/* Automating room checks, providing live training, <br />
              bye paperwork and more. */}
            </p>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="block">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="circle sml"></div>
      <div className="circle mid"></div>
      <div className="circle lrg"></div>
    </>
  );
};

export default Main;
