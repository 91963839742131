import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppButton from "../../../../components/AppButton";
import AppSelect from "../../../../components/AppSelect";
import AppInput from "../../../../components/AppInput";
import AppTextarea from "../../../../components/AppTextarea";
import AppDateTimePicker from "../../../../components/AppDateTimePicker";
import moment from "moment";
import Network from "../../../../Network/Network";
import API from "../../../../Network/API";
import { toast } from "react-toastify";
import { numberOnlyRegex, numberCommaOnlyRegex } from "../../../../utils/Regex";
import { maxLengthTextarea } from "../../../../utils/constant";

interface ModalTypes {
  show: boolean;
  onClose: any;
  type: string;
  task_assignment_id?: string | null;
}

type SelectType = { label: string; value: string };

interface FormTypes {
  hotel: SelectType | undefined | string;
  assigned_date: Date | null;
  task: SelectType | undefined | string;
  room_no: string;
  sub_area: string;
  user: SelectType | undefined | string;
  camera: SelectType | undefined | string;
  notes: string;
}

const AssignTaskModal: React.FC<ModalTypes> = ({
  show,
  onClose,
  type,
  task_assignment_id,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  const [hotels, setHotels] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  const [addMore, setAddMore] = useState(false);
  const [minDate, setMinDate] = useState<any>(moment());

  const fetchHotels = () => {
    try {
      Network.get(API.hotelList)
        .then((resp: any) => {
          setHotels(
            resp.data.hotels.map((hotel: any) => ({
              value: hotel._id,
              label: hotel.name,
              tasks: hotel.tasks || [],
              cleaner: hotel.cleaner,
            }))
          );
        })
        .catch(console.log);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit: SubmitHandler<FormTypes> = (data: any) => {
    const submitData = {
      _id: task_assignment_id,
      hotel: data.hotel.value,
      assigned_date: data.assigned_date,
      task: data.task.value,
      area: data.task.area,
      room_no: data.room_no,
      sub_area: data.sub_area,
      user: data.user?.value,
      camera: data.camera?.value,
      notes: data.notes,
    };

    try {
      Network.post(API.upsertTaskAssignment, submitData)
        .then((response: any) => {
          if (!addMore) {
            onClose(response?.status);
          } else {
            setValue("room_no", "");
            setValue("sub_area", "");
            setValue("user", "");
            setValue("camera", "");
            setValue("notes", "");
          }
          toast.success("Task Assignment upsertion successful!");
        })
        .catch((error: any) => {
          console.error(error);
          toast.error(error.response.data.message || "Something went wrong!");
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTaskAssignmentById = () => {
    try {
      Network.get(API.fetchTaskAssignmentById, { id: task_assignment_id })
        .then((resp: any) => {
          const { task_assignment } = resp.data;
          const hotel: any = hotels.find(
            (h: any) => h.value === task_assignment.hotel
          );
          setValue("hotel", hotel);
          const hotel_tasks =
            hotel?.tasks?.map((task: any) => ({
              value: task._id,
              label: task.task,
              area: task.area,
            })) || [];
          const hotel_cleaners =
            hotel?.cleaner?.map((c: any) => ({
              value: c.cleaners._id,
              label: c.cleaners.name || c.cleaners.email,
              camera: c.cleaners.camera,
            })) || [];
          setTasks(hotel_tasks);
          setCleaners(hotel_cleaners);
          setValue(
            "task",
            hotel_tasks?.find((t: any) => t.value === task_assignment?.task)
          );
          setValue(
            "user",
            hotel_cleaners?.find((c: any) => c.value === task_assignment?.user)
          );

          setValue("room_no", task_assignment?.room_no);
          setValue("sub_area", task_assignment?.sub_area);
          setValue("notes", task_assignment?.notes);
          setValue("assigned_date", task_assignment?.assigned_date);

          if (
            // type === "Edit" &&
            moment(task_assignment?.assigned_date).isSameOrAfter(moment())
          ) {
            setMinDate(moment());
          } else {
            setMinDate(moment(task_assignment?.assigned_date));
          }
        })
        .catch(console.log);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    task_assignment_id && hotels?.length && fetchTaskAssignmentById();
  }, [task_assignment_id, hotels]);

  useEffect(() => {
    fetchHotels();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {(type === "Add" ? "Assign" : "Edit Assigned") || null} Task
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4">
            <Controller
              name="hotel"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Building"
                  options={hotels}
                  value={value || ""}
                  onChange={(e) => {
                    if (!e) {
                      setValue("task", "");
                      setValue("user", "");
                    }
                    setTasks(
                      e?.tasks?.map((task: any) => ({
                        value: task._id,
                        label: task.task,
                        area: task.area,
                      })) || []
                    );
                    setCleaners(
                      e?.cleaner?.map((c: any) => ({
                        value: c.cleaners._id,
                        label: c.cleaners.name || c.cleaners.email,
                        camera: c.cleaners.camera,
                      })) || []
                    );

                    onChange(e);
                  }}
                  isRequired
                />
              )}
            />
            {errors?.hotel?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="assigned_date"
              control={control}
              // defaultValue={moment().toDate()}
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppDateTimePicker
                  value={value}
                  onChange={onChange}
                  isRequired
                  label="Date of Assignment"
                  minDate={minDate}
                />
              )}
            />
            {errors?.assigned_date?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="task"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Task"
                  options={tasks}
                  value={value || ""}
                  onChange={onChange}
                  isRequired
                />
              )}
            />
            {errors?.task?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="room_no"
              control={control}
              defaultValue=""
              rules={{
                required: false,
                pattern: task_assignment_id
                  ? numberOnlyRegex
                  : numberCommaOnlyRegex,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Room No."
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            <span className="form-status weight-500 d-block mt-2">
              {type === "Add"
                ? "Note: You can add multiple room numbers by using comma(,). Eg. 001,002,003"
                : type === "Edit"
                ? "Note: You can add only one room number. Eg. 001"
                : null}
            </span>
            {errors?.room_no?.type === "pattern" && (
              <span className="form-status error">
                {task_assignment_id
                  ? "Only number is allowed in edit mode"
                  : "Only number and comma is allowed in add mode"}
              </span>
            )}
          </div>
          <div className="mb-4">
            <Controller
              name="sub_area"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Sub Area"
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="user"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Assignee Name"
                  options={cleaners}
                  value={value || ""}
                  onChange={(e) => {
                    if (e && e.camera) {
                      setValue("camera", {
                        value: e.camera._id,
                        label: e.camera.name || e.camera.camera_unique_id,
                      });
                    } else {
                      setValue("camera", undefined);
                    }
                    onChange(e);
                  }}
                  itemMissingText={`Note: Make sure "Helpee" users are assigned to the corresponding building`}
                  // isRequired
                  // isDisabled={!cleaners.length}
                />
              )}
            />
            <span className="form-status weight-500 d-block mt-2">
              Note: Make sure "Helpee" users are assigned to the corresponding
              building
            </span>
            {/* {errors?.user?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )} */}
          </div>
          <div className="mb-4">
            <Controller
              name="camera"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Assign Camera"
                  options={[]}
                  value={value || ""}
                  onChange={onChange}
                  // isRequired
                  isDisabled={true}
                />
              )}
            />
            {/* {errors?.camera?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )} */}
          </div>
          <div className="m-0">
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              rules={{
                required: false,
                maxLength: maxLengthTextarea,
              }}
              render={({ field: { value, onChange } }) => (
                <AppTextarea
                  label="Notes"
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            {errors?.notes?.type === "maxLength" && (
              <span className="form-status error">
                Maximum of {maxLengthTextarea} characters allowed
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <AppButton
            type="submit"
            className="primary"
            onClick={() => setAddMore(false)}
          >
            Save
          </AppButton>
          {!task_assignment_id && (
            <AppButton
              type="submit"
              className="primary"
              onClick={() => setAddMore(true)}
            >
              Save and Add More
            </AppButton>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AssignTaskModal;
