import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Network from "../../Network/Network";
import API from "../../Network/API";
import Hls from "hls.js";
import { toast } from "react-toastify";
import AppButton from "../../components/AppButton";
import { cameraManufacturers } from "../../utils/constant";

const SOSIncident = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { id } = useParams();

  const [cameraManufacturer, setCameraManufacturer] = useState("");
  const [cameraModel, setCameraModel] = useState("");
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    fetchSOSIncidentById();
  }, [id]);

  const fetchSOSIncidentById = () => {
    try {
      Network.get(API.fetchSOSIncidentById, { id })
        .then((resp: any) => {
          setCameraManufacturer(resp?.data?.device_manufacturer);
          setCameraModel(resp?.data?.device_model);
          setDeviceType(resp?.data?.device_type);
          if (Hls.isSupported()) {
            const hls = new Hls();
            // @ts-ignore
            hls.loadSource(resp.data.hlsStream?.HLSStreamingSessionURL);
            // @ts-ignore
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              // @ts-ignore
              videoRef.current.play();
            });
          } else if (
            // @ts-ignore
            videoRef.current.canPlayType("application/vnd.apple.mpegurl")
          ) {
            // @ts-ignore
            videoRef.current.src = resp.data.hlsStream.HLSStreamingSessionURL;
            // @ts-ignore
            videoRef.current.addEventListener("loadedmetadata", () => {
              // @ts-ignore
              videoRef.current.play();
            });
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            error?.response?.data?.message ||
              "Error occured while rendering the video!"
          );
        });
    } catch (error) {
      console.error(error);
    }
  };
  const onPlay = () => {
    videoRef.current?.play();
  };

  return (
    <>
      <div className="d-flex flex-column">
        <video
          ref={videoRef}
          // controls
          height={"500px"}
          width={"500px"}
          style={{
            rotate: !deviceType
              ? cameraManufacturer?.toLowerCase() === "motorola"
                ? cameraModel === "V500" || cameraModel === "VB400"
                  ? "0deg"
                  : "90deg"
                : cameraManufacturers.includes(
                    cameraManufacturer?.toLowerCase() || ""
                  )
                ? "0deg"
                : "90deg"
              : deviceType === "mobile"
              ? "90deg"
              : "0deg",
          }}
        />
        <div className="d-flex mt-3">
          <AppButton
            className="primary mx-auto w-100 w-md-auto flex-shrink-0"
            onClick={onPlay}
          >
            Play
          </AppButton>
        </div>
      </div>
    </>
  );
};

export default SOSIncident;
