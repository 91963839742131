import { FC } from "react";
import Logo from "@/images/logo--icon.svg";
import { Link } from "react-router-dom";
import Arrow from "@/images/icon__formnext.svg";

const NotFound: FC = () => {
  return (
    <div className="site-404">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-4 d-flex flex-column align-items-center">
            <img src={Logo} alt="" />
            <h2 className="mb-3">Something's wrong here...</h2>
            <p className="mb-4 pb-2">
              The page you were looking for doesn't exist. You may have mistyped
              the address or the page may have moved.
            </p>
            <Link to="/">
              Go to Home Page <img src={Arrow} alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
