import React from "react";
import { toast } from "react-toastify";
import IconSignIn from "@/images/icon__signin.svg";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../components/AppInput";
import AppButton from "../../components/AppButton";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import Main from "./Main";
import { emailRegex } from "../../utils/Regex";
import API from "../../Network/API";
import Network from "../../Network/Network";

import {
  endLoading,
  startLoading,
} from "../../store/slices/loader/loaderSlice";

interface ForgotPasswordFormTypes {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const { title } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormTypes>({
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormTypes> = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.sendForgotPasswordEmail, data);
      toast.success("Password reset mail has been sent to registered email");
      dispatch(endLoading());
      navigate("/");
    } catch (e: any) {
      toast.error(e.response.data.message);
      dispatch(endLoading());
      console.error("e", e);
    }
  };

  return (
    <>
      <Main>
        <h1 className="mb-2">{title}</h1>
        <p className="color-light text--lrg mb-4 pb-md-2">
          Enter your email/account id &amp; we'll send you a link{" "}
          <br className="d-none d-xl-inline" /> to reset your password
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 pb-2">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: (value: any) => {
                  if (value.indexOf("@") > -1) {
                    const check = emailRegex.test(value);
                    if (!check) {
                      return "Provide proper email";
                    }
                  } else {
                    if (isNaN(value)) {
                      return "Account Id should be numerical";
                    } else {
                      if (value.length != 7) {
                        return "Account Id should be of 7 digit";
                      }
                    }
                  }
                },
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Account ID/Email"
                  isRequired
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
            {errors?.email?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
            {errors?.email?.type === "validate" && (
              <span className="form-status error">
                {errors?.email?.message}
              </span>
            )}
          </div>

          <div className="d-flex align-items-center">
            <AppButton custom type="submit">
              Send Link
              <img src={IconSignIn} className="ms-3" alt="" />
            </AppButton>
            <Link className=" weight-500 text--sml ms-auto" to={"/"}>
              Back to Sign In
            </Link>
          </div>
        </form>
      </Main>
    </>
  );
};

export default ForgotPassword;
