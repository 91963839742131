import { FC } from "react";
import Logo from "@/images/logo--icon--white.svg";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const AppLoader: FC = () => {
  const { isLoading } = useSelector((state: RootState) => state?.loader);

  if (isLoading) {
    return (
      <div className="loader">
        <div className="inner">
          <img src={Logo} alt="" />
        </div>
      </div>
    );
  }
};

export default AppLoader;
