import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import AppButton from "../../../components/AppButton";
import CameraSOS from "./CameraSOS";
import Slider from "react-slick";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CameraActive from "./CameraActive";
import { backIcon } from "../../../layout/Header";
import { getVideoStreamURL } from "../../../utils/hls";
import StartTask from "./StartTaskModal";
import moment from "moment";
import Network from "../../../Network/Network";
import API from "../../../Network/API";
import { cameraManufacturers } from "../../../utils/constant";

const CameraDetails: React.FC = () => {
  // const { name } = useSelector((state: RootState) => state?.user);
  const [searchParams] = useSearchParams();
  const { cameraId } = useParams();
  const videoRef = useRef<HTMLVideoElement>(null);
  const type = searchParams.get("type");
  const cleanerId = searchParams.get("cleanerId");
  const { title, backURL } = useSelector((state: RootState) => state?.page);
  const navigate = useNavigate();
  const { allCameras } = useSelector((state: RootState) => state?.camera);
  const [callStarted, _] = useState(false);
  const [showStartTaskModal, setShowStartTaskModal] = useState(false);
  const [taskType, setTaskType] = useState("start");
  const [sliderCams, setSliderCams] = useState<any>([]);
  const [selectedCamera, setSelectedCamera] = useState<any>(null);

  const settingsV = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  const settingsH = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    if (cameraId) {
      getVideoStreamURL(cameraId, videoRef);
    }
  }, [cameraId]);

  useEffect(() => {
    if (allCameras && !!allCameras?.length && cameraId) {
      const cam = allCameras?.find((cam) => cam?.channel_name === cameraId);
      setSelectedCamera(cam);

      if (cam?.status === "stop") {
        if (sliderCams && sliderCams?.length) {
          navigate(
            `/camera-management/details/${
              sliderCams[0]?.channel_name
            }?cleanerId=${sliderCams[0]?.user?._id}&type=${
              sliderCams[0]?.sos ? "sos" : "active"
            }`
          );
        } else {
          navigate(`/camera-management`);
        }
        return;
      }

      if (cam?.sos) {
        navigate(
          `/camera-management/details/${cam?.channel_name}?cleanerId=${cam?.user?._id}&type=sos`
        );
      } else {
        if (sliderCams && sliderCams?.length && type === "sos") {
          navigate(
            `/camera-management/details/${sliderCams[0]?.channel_name}?cleanerId=${sliderCams[0]?.user?._id}&type=sos`
          );
        } else {
          navigate(
            `/camera-management/details/${cam?.channel_name}?cleanerId=${cam?.user?._id}&type=active`
          );
        }
      }
    }
  }, [allCameras, cameraId]);

  const openStartModal = (type: string) => {
    setTaskType(type);
    setShowStartTaskModal(true);
  };
  const closeStartModal = () => {
    setTaskType("start");
    setShowStartTaskModal(false);
  };

  useEffect(() => {
    if (allCameras && !!allCameras?.length) {
      if (type === "sos") {
        const cams = allCameras?.filter(
          (cam) => cam?.channel_name !== cameraId && cam?.sos
        );
        setSliderCams(cams);
      } else if (type === "active") {
        const cams = allCameras?.filter(
          (cam) =>
            cam?.channel_name !== cameraId && cam.status === "play" && !cam?.sos
        );
        setSliderCams(cams);
      } else {
        setSliderCams([]);
      }
    }
  }, [allCameras, selectedCamera]);

  const onEndSos = (camera_id: string) => {
    Network.get(API.endSos, {
      cameraId: camera_id,
    });
  };

  return (
    <>
      {(!!backURL && (
        <h1 className="mb-4 d-flex d-md-none align-items-center">
          <AppButton
            custom
            className="button--back"
            onClick={() => navigate(backURL)}
          >
            {backIcon}
          </AppButton>
          {title !== "Registered Cameras" && title}
        </h1>
      )) ||
        null}
      <div className="row h-lg-100">
        <div className="col-lg-8 d-flex flex-column">
          <h3 className="flex-shrink-0">
            {selectedCamera?.user?.name || selectedCamera?.user?.email}
          </h3>
          <div className="camera-details bg-background--light rounded--24 p-3 flex-grow-1 d-flex flex-column">
            <div
              className="screen flex-grow-1 d-flex flex-column align-items-start p-4"
              // style={
              //   {
              //     backgroundImage: `url(${SampleGraphics})`,
              //   }
              // }
            >
              <video
                ref={videoRef}
                // controls
                style={{
                  rotate: !selectedCamera?.device_type
                    ? selectedCamera?.device_manufacturer?.toLowerCase() ===
                      "motorola"
                      ? selectedCamera?.cameraModel === "V500" ||
                        selectedCamera?.cameraModel === "VB400"
                        ? "0deg"
                        : "90deg"
                      : cameraManufacturers.includes(
                          selectedCamera?.device_manufacturer?.toLowerCase()
                        )
                      ? "0deg"
                      : "90deg"
                    : selectedCamera?.device_type === "mobile"
                    ? "90deg"
                    : "0deg",
                }}
                className="w-100 h-100"
              ></video>
              {(selectedCamera && selectedCamera.sos && (
                <span className="alertSOS sml zIndex--1">
                  <span>SOS</span>
                </span>
              )) ||
                null}
              <span className="live position-relative zIndex--1">Live</span>
              <div className="d-flex align-items-center weight-500 color-white mt-auto w-100 position-relative zIndex--1">
                <span>{moment().format("MMMM DD, YYYY")}</span>
                <span className="ms-auto">{moment().format("hh:mm a")}</span>
                {/* <span className="ms-auto">Room 101</span> */}
              </div>
            </div>
            <div className="d-md-flex align-items-center flex-shrink-0">
              <div className="d-flex align-items-center me-md-3">
                <AppButton
                  className="primary rounded py-2 w-100 w-md-auto me-2 me-md-0"
                  onClick={() => openStartModal("start")}
                >
                  Start Task
                  {/* {(mute && (
                  <svg
                    width="9"
                    height="13"
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2.52858 12.6661C2.4153 12.6661 2.30346 12.6351 2.21002 12.5702C2.18838 12.5552 2.16817 12.5388 2.15086 12.5198C2.02278 12.3812 1.99861 12.2127 2.07329 12.0376C2.14689 11.8654 2.28398 11.7767 2.46725 11.7756C2.95645 11.772 3.44601 11.7738 3.93521 11.7734C3.97923 11.7734 4.02324 11.7734 4.07483 11.7734V10.5918C3.85801 10.5436 3.63686 10.5068 3.42328 10.4444C2.40628 10.1482 1.62666 9.53347 1.08118 8.61961C0.711032 7.99979 0.534616 7.32087 0.515857 6.59817C0.507198 6.26546 0.742419 6.04803 1.05051 6.1075C1.24677 6.14544 1.3835 6.30012 1.39433 6.51573C1.41453 6.91666 1.47297 7.30774 1.61259 7.68751C2.11081 9.04133 3.5979 9.91251 5.00453 9.65787C6.23944 9.43424 7.06307 8.71191 7.49058 7.52407C7.60711 7.20048 7.6302 6.86011 7.65365 6.51974C7.67782 6.17426 8.06636 5.97799 8.34668 6.17645C8.48053 6.2713 8.53428 6.40993 8.53284 6.57337C8.51732 8.34344 7.38163 9.8939 5.70694 10.4218C5.50203 10.4864 5.28629 10.5166 5.0756 10.5626C5.04061 10.5703 5.00561 10.5768 4.96845 10.5841V11.7731C5.01427 11.7731 5.0572 11.7731 5.10013 11.7731C5.59366 11.7731 6.08683 11.7716 6.58036 11.7752C6.76363 11.7767 6.90108 11.865 6.97432 12.0376C7.049 12.2127 7.02483 12.3816 6.89675 12.5198C6.87944 12.5388 6.85923 12.5552 6.83759 12.5702C6.74451 12.6351 6.63231 12.6661 6.51903 12.6661H2.52858Z" />
                    <path d="M6.97585 4.52283H6.98776C6.98776 3.87164 6.98884 3.22045 6.98703 2.56962C6.98631 2.22523 6.93328 1.89106 6.80918 1.56711C6.34162 0.3479 4.92813 -0.306576 3.7192 0.141415C2.68957 0.523011 2.06652 1.42337 2.06328 2.55138C2.05931 3.8534 2.06003 5.15542 2.06436 6.45744C2.06508 6.64605 2.07807 6.83831 2.11559 7.02217C2.42693 8.55439 4.01864 9.43578 5.44583 8.84041C6.37589 8.45224 6.90983 7.72006 6.96791 6.69749C7.0094 5.97479 6.97585 5.24772 6.97585 4.52283ZM6.12877 6.45963C6.12877 7.35525 5.41048 8.08159 4.52479 8.08159C3.63911 8.08159 2.92082 7.35525 2.92082 6.45963V2.56852C2.92082 1.67254 3.63875 0.94656 4.52479 0.94656C4.96782 0.94656 5.36863 1.12787 5.65905 1.42155C5.94911 1.71522 6.12877 2.12053 6.12877 2.56852V6.45963Z" />
                    <path d="M0.466661 10C0.349298 10 0.231576 9.95682 0.140773 9.87011C-0.0437058 9.69422 -0.0472949 9.4053 0.132518 9.22485L8.19969 1.1377C8.3795 0.957249 8.67524 0.953738 8.85936 1.12962C9.04348 1.30551 9.04743 1.59443 8.86761 1.77488L0.800445 9.86238C0.708924 9.95401 0.588331 10 0.466661 10Z" />
                  </svg>
                )) || (
                  <svg
                    width="12"
                    height="18"
                    viewBox="0 0 12 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3.49388 17.9569C3.34092 17.9569 3.18992 17.915 3.06376 17.8273C3.03454 17.8071 3.00726 17.785 2.98388 17.7594C2.81095 17.5722 2.77832 17.3446 2.87915 17.1082C2.97852 16.8757 3.16362 16.756 3.41107 16.7545C4.07158 16.7496 4.73259 16.7521 5.3931 16.7516C5.45253 16.7516 5.51196 16.7516 5.58161 16.7516V15.1561C5.28886 15.0911 4.99026 15.0414 4.7019 14.9571C3.32875 14.5572 2.27611 13.7272 1.53961 12.4933C1.03984 11.6564 0.801643 10.7398 0.776314 9.76399C0.764623 9.31476 1.08222 9.02119 1.4982 9.10148C1.76319 9.15271 1.9478 9.36156 1.96242 9.65267C1.98969 10.194 2.0686 10.722 2.25711 11.2348C2.92981 13.0627 4.93766 14.239 6.83688 13.8952C8.50425 13.5932 9.61631 12.6179 10.1935 11.0141C10.3509 10.5772 10.382 10.1177 10.4137 9.65808C10.4463 9.19162 10.9709 8.92662 11.3494 9.19458C11.5301 9.32264 11.6027 9.50982 11.6008 9.73049C11.5798 12.1204 10.0464 14.2139 7.78528 14.9266C7.5086 15.0138 7.21731 15.0547 6.93284 15.1167C6.88559 15.1271 6.83834 15.1359 6.78817 15.1458V16.7511C6.85003 16.7511 6.908 16.7511 6.96597 16.7511C7.63233 16.7511 8.2982 16.7491 8.96456 16.754C9.21201 16.756 9.3976 16.8752 9.49648 17.1082C9.59731 17.3446 9.56467 17.5727 9.39175 17.7594C9.36837 17.785 9.34109 17.8071 9.31187 17.8273C9.18619 17.915 9.0347 17.9569 8.88175 17.9569H3.49339H3.49388Z" />
                    <path d="M9.4985 6.96195H9.51457C9.51457 6.08271 9.51604 5.20348 9.5136 4.32473C9.51263 3.85975 9.44102 3.40855 9.27346 2.97115C8.64217 1.32499 6.73369 0.441318 5.1014 1.04619C3.7112 1.56142 2.86996 2.77708 2.86558 4.3001C2.86022 6.05808 2.8612 7.81606 2.86704 9.57404C2.86802 9.8287 2.88555 10.0883 2.93621 10.3365C3.35658 12.4053 5.50569 13.5954 7.43268 12.7915C8.68844 12.2674 9.40936 11.2788 9.48778 9.89815C9.5438 8.92237 9.4985 7.94068 9.4985 6.96195Z" />
                  </svg>
                )} */}
                </AppButton>
                <AppButton
                  onClick={() => openStartModal("end")}
                  className={`danger rounded py-2 ms-2 ms-md-3 w-100 w-md-auto ${
                    (callStarted && "end") || ""
                  }`}
                >
                  Complete Task
                  {/* <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3.01627C0.0128378 2.08257 0.31361 1.18556 0.984844 0.424288C1.03436 0.367423 1.09305 0.317894 1.15174 0.268366C1.54237 -0.0526508 1.95318 -0.0856697 2.3915 0.169309C2.67027 0.330735 2.89951 0.549027 3.09392 0.800337C3.58175 1.42953 3.92471 2.12109 3.9999 2.92455C4.0109 3.05112 4.00173 3.18503 3.97973 3.30977C3.93571 3.56108 3.79449 3.74085 3.55058 3.83257C3.42953 3.87843 3.30666 3.92062 3.18561 3.96465C2.7693 4.11873 2.61525 4.40123 2.71612 4.83781C2.81699 5.27623 3.04073 5.65778 3.28648 6.02466C3.92287 6.97487 4.7555 7.69761 5.8082 8.15437C5.96592 8.22225 6.14015 8.25893 6.31071 8.28278C6.59681 8.3213 6.82972 8.18739 6.95443 7.92691C7.00395 7.82235 7.0388 7.71229 7.07731 7.60223C7.2442 7.10878 7.51563 6.93268 8.04014 6.97487C8.48763 7.00972 8.90395 7.1473 9.30375 7.33991C9.80626 7.58205 10.2666 7.88839 10.6242 8.32497C11.1414 8.956 11.0919 9.55584 10.461 10.075C9.65404 10.7409 8.71321 11.0252 7.67518 10.9702C6.45926 10.9059 5.36621 10.462 4.34285 9.83283C2.49237 8.69735 1.17008 7.11611 0.390637 5.08729C0.144884 4.44525 0.00916987 3.77754 0 3.01627Z" />
                </svg> */}
                </AppButton>
              </div>
              {selectedCamera?.sos && (
                <AppButton
                  onClick={() => onEndSos(selectedCamera?._id)}
                  className="success rounded ms-auto py-2 w-100 w-md-auto mt-3 mt-md-0"
                >
                  End SOS
                </AppButton>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4 position-relative d-none d-lg-flex flex-column">
          <h3 className="pe-5 flex-shrink-0">
            Other {(type === "sos" && "SOS Alerts") || "Active Cameras"}
          </h3>
          <div
            className={`cameras slider vertical m-0 flex-grow-1 ${
              (type === "sos" && "sos") || "active"
            }`}
          >
            {(type === "sos" && (
              <>
                {(sliderCams && !!sliderCams?.length && (
                  <Slider {...settingsV}>
                    {sliderCams?.map((cam: any) => (
                      <div key={cam._id}>
                        <CameraSOS
                          streamName={cam?.channel_name as string}
                          cleanerId={cam.user?._id}
                          cleanerName={cam.user?.name || cam.user?.email}
                          cameraManufacturer={cam?.device_manufacturer}
                          cameraModel={cam?.device_model}
                          deviceType={cam?.device_type}
                        />
                      </div>
                    ))}
                  </Slider>
                )) || (
                  <span className="weight-500 color-error">
                    No other SOS cams available !
                  </span>
                )}
              </>
            )) || (
              <>
                {(sliderCams && !!sliderCams?.length && (
                  <Slider {...settingsV}>
                    {sliderCams?.map((cam: any) => (
                      <div key={cam._id}>
                        <CameraActive
                          streamName={cam?.channel_name as string}
                          cleanerId={cam.user?._id}
                          cleanerName={cam.user?.name || cam.user?.email}
                          cameraManufacturer={cam?.device_manufacturer}
                          cameraModel={cam?.device_model}
                          deviceType={cam?.device_type}
                        />
                      </div>
                    ))}
                  </Slider>
                )) || (
                  <span className="weight-500 color-error">
                    No other Active cams available !
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-5 d-lg-none position-relative">
          <h3 className="pe-5">
            Other {(type === "sos" && "SOS Alerts") || "Active Cameras"}
          </h3>
          <div
            className={`cameras m-0 ${(type === "sos" && "sos") || "active"}`}
          >
            <div className="slider-container">
              {(type === "sos" && (
                <>
                  {(sliderCams && !!sliderCams?.length && (
                    <Slider {...settingsH}>
                      {sliderCams?.map((cam: any) => (
                        <div key={cam._id}>
                          <CameraSOS
                            streamName={cam?.channel_name as string}
                            cleanerId={cam.user?._id}
                            cleanerName={cam.user?.name || cam.user?.email}
                            cameraManufacturer={cam?.device_manufacturer}
                            cameraModel={cam?.device_model}
                            deviceType={cam?.device_type}
                          />
                        </div>
                      ))}
                    </Slider>
                  )) || (
                    <span className="weight-500 color-error">
                      No other SOS cams available !
                    </span>
                  )}
                </>
              )) || (
                <>
                  {(sliderCams && !!sliderCams?.length && (
                    <Slider {...settingsH}>
                      {sliderCams?.map((cam: any) => (
                        <div key={cam._id}>
                          <CameraActive
                            streamName={cam?.channel_name as string}
                            cleanerId={cam.user?._id}
                            cleanerName={cam.user?.name || cam.user?.email}
                            cameraManufacturer={cam?.device_manufacturer}
                            cameraModel={cam?.device_model}
                            deviceType={cam?.device_type}
                          />
                        </div>
                      ))}
                    </Slider>
                  )) || (
                    <span className="weight-500 color-error">
                      No other Active cams available !
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {allCameras && cleanerId && showStartTaskModal && (
        <StartTask
          show={showStartTaskModal}
          onClose={() => closeStartModal()}
          time={moment()}
          taskType={taskType}
          cleaner_id={cleanerId as string}
        />
      )}
    </>
  );
};

export default CameraDetails;
