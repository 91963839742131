import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import AppSelect from "../../../../components/AppSelect";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import AppInput from "../../../../components/AppInput";
import AppButton from "../../../../components/AppButton";
import IconNext from "@/images/icon__formnext.svg";
import { useSelector, useDispatch } from "react-redux";
import { updateUserRegistrationFormState } from "../../../../store/slices/user/userRegistrationFormSlice";
import { RootState } from "../../../../store/store";
import API from "../../../../Network/API";
import Network from "../../../../Network/Network";
import { toast } from "react-toastify";

type SelectType = { label: string; value: string };

interface FormTypes {
  name: string;
  hotels: SelectType[] | undefined | string;
  role: SelectType | null;
  employee_id: string;
}

const UserInfo = forwardRef(({}, ref) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userFormState = useSelector(
    (state: RootState) => state?.userRegistrationForm
  );
  const { userId } = useParams();
  const [allHotels, setAllHotels] = useState([]);
  const [allRoles, setAllRoles] = useState([]);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
    watch,
  } = useForm<FormTypes>({
    mode: "onChange",
  });

  useImperativeHandle(ref, () => ({
    triggerUserStep1FormValidation: async () => {
      if (userFormState?.is_step_1_complete) {
        const valid = await trigger();
        if (valid) {
          const updateUserRegistrationFormStateValue: any = {
            name: getValues("name"),
            hotels: getValues("hotels"),
            role: getValues("role"),
            employee_id: getValues("employee_id"),
          };
          dispatch(
            updateUserRegistrationFormState(
              updateUserRegistrationFormStateValue
            )
          );
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  }));

  const getAllHotels = async () => {
    try {
      const hotels = await Network.get(API.hotelList);
      setAllHotels(
        hotels.data.hotels.map((a: any) => {
          return { label: a.name, value: a._id };
        })
      );
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  const getAllRoles = async () => {
    try {
      const roles = await Network.get(API.fetchRoles);
      setAllRoles(roles.data.roles);
    } catch (e: any) {
      toast.error(e.response.data.message);
    }
  };

  useEffect(() => {
    userFormState?.name && setValue("name", userFormState?.name);
    userFormState?.hotels && setValue("hotels", userFormState?.hotels);
    userFormState?.role && setValue("role", userFormState?.role);
    userFormState?.employee_id &&
      setValue("employee_id", userFormState?.employee_id);

    getAllHotels();
    getAllRoles();
  }, []);

  useEffect(() => {
    watch("role")?.value === "supervisor" || watch("role")?.value === "cleaner"
      ? (setValue("hotels", userFormState?.hotels || []),
        setValue("employee_id", userFormState?.employee_id || ""))
      : watch("role")?.value === "owner"
      ? (setValue("hotels", userFormState?.hotels || []),
        setValue("employee_id", ""))
      : (setValue("hotels", ""), setValue("employee_id", ""));
  }, [watch("role")]);

  useEffect(() => {
    if (
      userFormState?.user_form_type === "add" &&
      userFormState?.is_all_state_reset
    ) {
      dispatch({ type: "RESET_FORM" });
      reset();
    }
  }, [userFormState?.user_form_type, userFormState?.is_all_state_reset]);

  const onSubmit: SubmitHandler<FormTypes> = (data) => {
    //console.log(data);
    const updateUserRegistrationFormStateValue: any = {
      is_step_1_complete: true,
      name: data.name,
      hotels: data?.hotels,
      role: data?.role,
      employee_id: data.employee_id,
      is_all_state_reset: false,
    };
    dispatch(
      updateUserRegistrationFormState(updateUserRegistrationFormStateValue)
    );

    if (userFormState?.user_form_type === "add") {
      navigate("/user-management/add?step=2");
    } else {
      navigate(`/user-management/edit/${userId}?step=2`);
    }
  };

  return (
    <>
      <h3 className="color-primary">Step {step}</h3>
      <h4 className="mb-4 pb-2">User Details</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 60,
            }}
            render={({ field: { value, onChange } }) => (
              <AppInput
                label="User Name"
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
          {errors?.role?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
          {errors?.name?.type === "maxLength" && (
            <span className="form-status error">
              Name can be of maximum 60 characters
            </span>
          )}
        </div>

        <div className="mb-4">
          <Controller
            name="role"
            control={control}
            defaultValue={null}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <AppSelect
                label="Role"
                options={allRoles}
                value={value || ""}
                onChange={onChange}
                isRequired
              />
            )}
          />
          {errors?.role?.type === "required" && (
            <span className="form-status error">This field is required</span>
          )}
        </div>

        {watch("role")?.value === "owner" ||
        watch("role")?.value === "supervisor" ||
        watch("role")?.value === "cleaner" ? (
          <div className="mb-4">
            <Controller
              name="hotels"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <AppSelect
                  label="Building Name"
                  options={allHotels}
                  value={value || ""}
                  onChange={onChange}
                  isMulti={true}
                  isRequired
                />
              )}
            />
            {errors?.hotels?.type === "required" && (
              <span className="form-status error">This field is required</span>
            )}
          </div>
        ) : (
          ""
        )}

        {watch("role")?.value === "supervisor" ||
        watch("role")?.value === "cleaner" ? (
          <div className="m-0">
            <Controller
              name="employee_id"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field: { value, onChange } }) => (
                <AppInput
                  label="Employee ID"
                  value={value || ""}
                  onChange={onChange}
                />
              )}
            />
          </div>
        ) : (
          ""
        )}

        <div className="d-flex mt-4 pt-2">
          <AppButton
            custom
            type="submit"
            className="button--registration ms-auto"
          >
            Next
            <img src={IconNext} alt="" className="ms-2" />
          </AppButton>
        </div>
      </form>
    </>
  );
});

export default UserInfo;
